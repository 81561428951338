import { adaClient, backendClient } from './base.service'

export const AddressService = {

  'get': async( zipCode, category = 1 ) => {
    const response = await backendClient.get( `/v2/endereco/consulta-cep/${zipCode}/${category}` )
    const { data } = response
    const translatedData = {
      'city': data.cidade,
      'district': data.bairro,
      'ibgeCode': data.ibge,
      'shippingFee': data.frete,
      'state': data.uf,
      'street': data.rua,
      'workDaysDelivery': data.dias_uteis_entrega,
      'zipCode': data.cep,
    }
    return { ...response, 'data': translatedData }
  },

  'post': async( data ) => {
    const body = {
      'endereco': {
        'bairro': data.district,
        'cep': data.zipCode,
        'cidade': data.city,
        'complemento': data.complement,
        'ibge': data.ibgeCode,
        'numero': data.number,
        'rua': data.street,
        'uf': data.state,
      },
      'lead': {
        'email': data.email
      }
    }
    const { 'data': responseBody } = await adaClient.post( '/v1/address', body )

    return {
      'city': responseBody.cidade,
      'complement': responseBody.complemento,
      'district': responseBody.bairro,
      'ibgeCode': responseBody.ibge,
      'id': responseBody.id,
      'number': responseBody.numero,
      'state': responseBody.uf,
      'street': responseBody.rua,
      'zipCode': responseBody.cep,
    }
  },
}
