import { MOBILE_THRESHOLD } from '@one-checkout/constants'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.expired {
    justify-content: flex-start;
    align-items: flex-start;
  }

  & > *:last-child {
    @media (max-width: ${MOBILE_THRESHOLD}px) {
      text-align: center;
    }
  }
`

export const Guide = styled.div`
  display: flex;
  flex-direction: column;
`

export const Image = styled.img`
  width: 258px;
  box-shadow: 0 1px 4px rgba(19, 26, 45, 0.2);
`

export const QRSection = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`
