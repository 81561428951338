import React, { useState } from 'react'
import { setIsSubscriber, setStatus } from '@one-checkout/actions/subscription.action'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { navigate } from 'gatsby'
import { resetEmail } from '@one-checkout/actions/identification.action'
import { BaseModal } from '../BaseModal'
import { messages } from './constants'
import { resetDocumentNumber } from '@one-checkout/actions/payment.action'
import { ReactComponent as Loader } from '../../assets/images/loader.svg'

const WHATSAPP_LINK = 'https://api.whatsapp.com/send/?phone=555191968623&text=Oi,+TAG!&app_absent=0'
const PRODUCT_URL_LINK = 'https://www.doispontos.com.br/nada-anonimas-9786588526286/p'

function ProductRedirectModal( { shouldDisplay, resetSteps }: ProductRedirectModal.Props ) {
  const [ showLoader, setShowLoader ] = useState( false )

  function handleButtonRedirectStore() {
    setShowLoader( true )
    navigate( PRODUCT_URL_LINK )
  }

  function handleButtonRetypeEmail() {
    resetSteps()
    resetEmail()
    resetDocumentNumber()
    setStatus( null )
    setShowLoader( false )
    setIsSubscriber( null )
  }

  return (
    <BaseModal shouldDisplay={ shouldDisplay }>
      <S.CenterContent>
        <S.Title>
          <Typography
            color="TAG1-700"
            variant="subtitle-large-bold"
          >
            { messages[ 'TITLE' ] }
          </Typography>
        </S.Title>
        <S.InformationEmail>
          <Typography
            color="TAG1-900"
            variant="text-medium-regular"
          >
            { messages[ 'INFORMATION_EMAIL' ] }
          </Typography>
        </S.InformationEmail>
        <S.InformationStore>
          <Typography
            color="TAG1-900"
            variant="text-medium-regular"
          >
            { messages[ 'INFORMATION_STORE' ] }
          </Typography>
        </S.InformationStore>
      </S.CenterContent>

      <S.ButtonStore
        name="account"
        onClick={ handleButtonRedirectStore }
        disabled={ showLoader }
      >
        {!showLoader ? (
          <Typography
            color="WHITE"
            variant="button-medium-bold"
          >
            ACESSAR LOJA
          </Typography>
        ) : (
          <Loader />
        )}
      </S.ButtonStore>

      <S.ButtonRetypeEmail
        name="retype-email"
        onClick={ handleButtonRetypeEmail }
      >
        <Typography
          color="TAG1-800"
          variant="subtitle-medium-regular"
        >
          INSERIR OUTRO EMAIL
        </Typography>

      </S.ButtonRetypeEmail>

      <S.Footer>
        <S.Contact>
          <Typography
            color="TAG1-800"
            variant="text-small-regular"
          >
            Em caso de dúvidas, fale conosco por Whatsapp:
            {' '}
            <a href={ WHATSAPP_LINK }>
              <S.Link>
                <Typography
                  color="TAG1-800"
                  variant="text-small-regular"
                >
                  51 99196 8623
                </Typography>
              </S.Link>
            </a>
          </Typography>
        </S.Contact>
      </S.Footer>
    </BaseModal>
  )
}

namespace ProductRedirectModal {
  export interface Props {
    shouldDisplay: boolean
    resetSteps: Function
  }
}

export { ProductRedirectModal }
