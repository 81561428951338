import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const subscriptionSlice = createSlice( {
  'name': 'subscription',
  'initialState': {
    'status': null,
    'isSubscriber': null,
    'email': '',
  } as subscriptionSlice.State,
  'reducers': {
    setSubscription( state, action: PayloadAction<subscriptionSlice.State> ) {
      Object.assign( state, action.payload )
    },
    setIsSubscriber( state, action:PayloadAction<boolean | null> ) {
      state.isSubscriber = action.payload
    },
    setStatus( state, action: PayloadAction<subscriptionSlice.Status | null> ) {
      state.status = action.payload
    },
    setEmail( state, action: PayloadAction<string> ) {
      state.email = action.payload
    }
  }
} )
namespace subscriptionSlice {
  export interface State {
    status: Status | null
    isSubscriber: boolean | null
    email: string
  }

  export type Status = 'ATIVO' | 'INATIVO' | ''
}

export default subscriptionSlice
