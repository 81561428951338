function mapIteration<$Item>( count: number, mapper: mapIteration.Callback<$Item> ): $Item[] {
  const array = Array<$Item>()

  for ( let i = 0; i < count; i++ ) {
    array.push( mapper( i, array ) )
  }

  return array
}
namespace mapIteration {
  export type Callback<$Item> = ( index: number, array: $Item[] ) => $Item
}

export { mapIteration }
