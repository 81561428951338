import { backendClient } from './base.service'
import cookie from 'cookie'

export const SubscriptionService = {
  'get': ( hash ) => {
    return backendClient.get( '/v2/venda-cobranca-status/' + hash, {
      'cache': {
        'maxAge': 0,
      },
    } )
  },
  'post': ( data ) => {
    const paymentMethodData = ( payment ) => {
      if ( payment.type === 'CARTAO_CREDITO' ) {
        return {
          'cartao_credito': {
            'ano_validade': payment.creditCard.expirationYear,
            'bandeira': payment.creditCard.flag,
            'mes_validade': payment.creditCard.expirationMonth,
            'nome': payment.creditCard.name,
            'token': payment.creditCard.token,
            'ultimos_digitos': payment.creditCard.lastDigits
          },
          'parcelas': payment.installments,
          'tipo': payment.type,
        }
      } else {
        return {
          'tipo': payment.type,
        }
      }
    }

    const {
      'partner_code': partnerCode,
      '_fbp': fbp,
    } = cookie.parse( document.cookie )

    const body = {
      'captcha': data.captcha,
      'categoria': {
        'id': data.category.id,
        'name': data.category.name
      },
      'cupons': data.coupons,
      'entrega': {
        'id': data.delivery.id
      },
      'id_mobdiq': data.idMobdiq,
      'indicacao': data.recommendationCode,
      'lead': data.lead,
      'multiplus': data.multiplus,
      'oferta': data.offer,
      'opcional': data.optional,
      'pagamento': paymentMethodData( data.payment ),
      'plano': {
        'id': data.plan.id,
        'name': data.plan.name
      },
      'partner_code': partnerCode,
      fbp,
      'utm': data.utm,
    }

    return backendClient.post( '/v2/assinatura', body )
  }
}
