import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 2px;

  h2 {
    max-width: 100%;
    margin: 0 0 8px;
  }
  
  button {
    margin-top: 24px;
  }
`

export { Container }
