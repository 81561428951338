interface Validation {
  REQUIRED_FIELD_MESSAGE: string
  MAX_LENGTH_MESSAGE: string
  EMPTY_COUPON: string
  PASSWORD_MIN_LENGTH_MESSAGE: string
  EMAIL_MIN_LENGTH_MESSAGE: string
  INVALID_NAME_MESSAGE: string
  INVALID_EMAIL_MESSAGE: string
  INVALID_CPF_MESSAGE: string
  INVALID_PHONE_MESSAGE: string
  INVALID_NUMBER_MESSAGE: string
  INVALID_FLAG_MESSAGE: string
  INVALID_DISTRICT_MESSAGE: string
  INVALID_CEP_MESSAGE: string
  INVALID_STREET_MESSAGE: string
  INVALID_COMPLEMENT_MESSAGE: string
  INVALID_UF_MESSAGE: string
  INVALID_CARD_MESSAGE: string
  INVALID_CVV_MESSAGE: string
  INVALID_CARD_EXPIRATION_MESSAGE: string
  INVALID_MONTH_MESSAGE: string
  EXPIRED_CARD_MESSAGE: string
  INVALID_BIRTH_DATE: string
  CONNECTION_ERROR_MESSAGE: string
  ALREADY_IN_USE: string
  STEP_ONE_FORWARD_ERROR_MESSAGE: string
}

export const validationTexts: Validation = {
  'REQUIRED_FIELD_MESSAGE': 'Campo obrigatório',
  'MAX_LENGTH_MESSAGE': 'Limite de caracteres excedido',
  'EMPTY_COUPON': 'Você deve inserir um cupom para aplicar um beneficio',
  'PASSWORD_MIN_LENGTH_MESSAGE': 'Tamanho mínimo 6 caracteres',
  'EMAIL_MIN_LENGTH_MESSAGE': 'Tamanho mínimo de 7 caracteres',
  'INVALID_NAME_MESSAGE': 'Nome inválido',
  'INVALID_EMAIL_MESSAGE': 'E-mail inválido',
  'INVALID_CPF_MESSAGE': 'CPF inválido',
  'INVALID_PHONE_MESSAGE': 'Telefone inválido',
  'INVALID_NUMBER_MESSAGE': 'Número inválido',
  'INVALID_FLAG_MESSAGE': 'Somente Visa, Master, Amex, Elo ou Hipercard',
  'INVALID_DISTRICT_MESSAGE': 'Bairro inválido',
  'INVALID_CEP_MESSAGE': 'CEP inválido',
  'INVALID_STREET_MESSAGE': 'Rua com caracteres inválidos.',
  'INVALID_COMPLEMENT_MESSAGE': 'Complemento com caracteres inválidos.',
  'INVALID_UF_MESSAGE': 'UF inválido',
  'INVALID_CARD_MESSAGE': 'Cartão inválido',
  'INVALID_CVV_MESSAGE': 'CVV inválido',
  'INVALID_CARD_EXPIRATION_MESSAGE': 'Validade inválida',
  'INVALID_MONTH_MESSAGE': 'Mês inválido',
  'EXPIRED_CARD_MESSAGE': 'Cartão expirado',
  'INVALID_BIRTH_DATE': 'Data de nascimento inválida',
  'CONNECTION_ERROR_MESSAGE': 'Ocorreu um erro de conexão. Por favor, entre em contato por contato@taglivros.com.br.',
  'ALREADY_IN_USE': 'Você já possui um cupom dessa promoção!',
  'STEP_ONE_FORWARD_ERROR_MESSAGE': 'Escolha sua caixinha e selecione um plano',
}
