import { DESKTOP_BREAKPOINT } from '@one-checkout/constants'
import styled from 'styled-components'

export const Container = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: all .8s ease-in-out;
`

export const Backdrop = styled.div`
  background-color: ${props => props.theme.v2.palette[ 'TAG1-800' ]};
  opacity: 0.7;
  position: absolute;
  height: 100%;
  width: 100%;
`

export const Card = styled.div`
  text-align: center;
  width: 100%;
  max-width: 500px;
  padding: 24px;
  margin: 16px;
  background-color: ${props => props.theme.v2.palette[ 'WHITE' ]};
  filter: drop-shadow(0px -2px 8px rgba(19, 26, 45, 0.3));
  border-radius: 24px;
  z-index: 1;

  & > p {
    margin-top: 8px;
  }

  @media(min-width: ${DESKTOP_BREAKPOINT}px) {
    text-align: left;
    padding: 24px 32px;
    border-radius: 16px;
    filter: drop-shadow(1px 1px 8px rgba(19, 26, 45, 0.3));
  }
`
