import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 350px;

  & > * {
    padding: 4px 10px;

    &:first-child > span {
      display: block;
      word-break: break-all;
      text-align: center;
    }

    &:last-child {
      display: flex;
      align-items: center;
      align-self: stretch;
      flex-shrink: 0;
      gap: 4px;
      width: fit-content;
      margin: auto;
      padding: 12px 24px 12px 16px;
      border-radius: 10px;
      background-color: ${props => props.theme.v2.palette[ 'TAG1-700' ]};
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`
