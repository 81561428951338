import React, { MouseEvent } from 'react'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'

function Modal( { shouldDisplay, title, content, buttonText, onButtonClick }: Modal.Props ) {
  return (
    <S.Container
      shouldDisplay={ shouldDisplay }
    >
      <S.Backdrop />
      <S.Card>
        <Typography
          variant="subtitle-large-bold"
          color="TAG1-700"
          as="h2"
        >
          {title}
        </Typography>

        <Typography
          variant="text-medium-regular"
          color="TAG1-900"
          as="p"
        >
          {content}
        </Typography>

        <S.Button
          type="button"
          onClick={ onButtonClick }
        >
          <Typography
            variant="button-medium-bold"
            color="WHITE"
          >
            {buttonText}
          </Typography>
        </S.Button>
      </S.Card>
    </S.Container>
  )
}

namespace Modal {
  export interface Props {
    shouldDisplay: boolean
    title: string
    content: string
    buttonText: string
    onButtonClick: ( event: MouseEvent<HTMLButtonElement> ) => void
  }
}

export { Modal }
