import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const identificationSlice = createSlice( {
  'name': 'identification',
  'initialState': {
    'name': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'email': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'phone': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'postalCode': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'city': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'state': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'street': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'number': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'complement': {
      'value': '',
      'isValid': true,
      'helperText': '',
      'disabled': false,
    },
    'district': {
      'value': '',
      'isValid': false,
      'helperText': '',
      'disabled': false,
    },
    'ibge': '',
  } as identificationSlice.State,
  'reducers': {
    setIdentificationData( state, action: PayloadAction<identificationSlice.SetIdentificationDataPayload> ) {
      Object.assign( state, action.payload )
    },
    resetEmail( state ) {
      state.email = identificationSlice.getInitialState().email
    },
  }
} )
namespace identificationSlice {
  export interface State {
    name: Field
    email: Field
    phone: Field
    postalCode: Field
    city: Field
    state: Field
    street: Field
    number: Field
    complement: Field
    district: Field
    ibge: string
  }

  export interface Field {
    value: string
    isValid: boolean
    helperText: string
    disabled: boolean
  }

  export type SetIdentificationDataPayload = Partial<State>
}

export default identificationSlice
