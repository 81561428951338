import styled from 'styled-components'
import { themeV2 as theme } from '@taglivros/tag-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: ${theme.palette[ 'CTA-400' ]}19;
  border-radius: 50px;
`
