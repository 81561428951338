import React from 'react'
import { Truck as TruckIcon } from 'react-feather'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import store from '@one-checkout/store'

function ShippingLabel() {
  const shipping = useSelector( ( state: store.State ) => state.shipping )
  const product = useSelector( ( state: store.State ) => state.product )
  const theme = useTheme().v2
  const deadline = shipping.deadline ? new Date( shipping.deadline ) : null
  const shippingFrom = product!.shippingFrom ? new Date( product!.shippingFrom ) : undefined
  const description = getDescription( deadline, shipping.monthlyPrice, product?.isSubscription, shippingFrom )

  if ( !description ) {
    return null
  }

  return (
    <S.Container>
      <TruckIcon color={ theme.palette[ 'TAG1-700' ] } />
      <Typography
        color="TAG1-700"
        variant="text-medium-bold"
      >
        {description}
      </Typography>
    </S.Container>
  )
}

function getDescription( deadline: Date | null, price: number | undefined, isSubscription: boolean | undefined, shippingFrom?: Date ) {
  if ( !deadline ) {
    return null
  }
  if ( !isSubscription && !shippingFrom ) {
    return `Frete Grátis. Entrega até ${parseDate( deadline )}`
  } else if ( !isSubscription && shippingFrom ) {
    return `Frete Grátis. Envios a partir de ${parseDate( shippingFrom )}`
  }
  return `+R$${price!.toFixed( 2 )}/mês. Entrega até ${parseDate( deadline )}`
}

function parseDate( date: Date ): string {
  return date.toLocaleDateString( 'pt-BR', { 'dateStyle': 'short' } ).replace( /\/\d+$/, '' )
}

export default ShippingLabel
