import styled from 'styled-components'

export const Title = styled.div`
  margin-bottom: 24px;
`

export const CenterContent = styled.div`
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  white-space: pre-line
`

export const InformationEmail = styled.div`
  margin-bottom: 12px;
`

export const InformationStore = styled.div`
  margin-bottom: 24px;
`

export const ButtonStore = styled.button`
  background-color: ${props => props.theme.v2.palette[ 'TAG1-700' ]};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: pointer;
`

export const ButtonRetypeEmail = styled.button`
  background-color: ${props => props.theme.v2.palette[ 'WHITE' ]};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.v2.palette[ 'TAG1-700' ]};
  margin-bottom: 12px;
  cursor: pointer;
`

export const Footer = styled.div`
  text-align: center;
  a {
    color: ${props => props.theme.cores[ 'TAG1-600' ]};
    font-family: inherit;
  }
`

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    flex-direction: row;
    a {
      margin-left: 4px;
    }
  }
`

export const Link = styled.text`
  color: ${props => props.theme.cores[ 'TAG1-800' ]};
  text-decoration: underline;
`
