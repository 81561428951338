import React, { ChangeEvent, FocusEvent, FormEvent, FormHTMLAttributes } from 'react'
import { FormInput } from '../FormInput'
import * as S from './styles'
import { FieldId } from '@one-checkout/interfaces/FormFields'
import { mask } from '@one-checkout/utils/masks'
import { validate } from '@one-checkout/utils/validation'

export function Form( { inputFields, onSubmit, onChange, onBlur, onFocus }: Form.Props ) {
  function handleSubmit( event: FormEvent<HTMLFormElement> ) {
    event.preventDefault()
    onSubmit( event )
  }

  function handleChange( event: ChangeEvent<HTMLFormElement> ) {
    const inputId = event.target.id as FieldId
    const inputValue = event.target[ 'value' ]
    const value = mask( inputId, inputValue )
    const validation = validate( inputId, value )

    onChange( inputId, value, validation.isValid, validation.helperText )
  }

  function handleBlur( event: FocusEvent<HTMLFormElement> ) {
    onBlur?.( event )
  }

  function handleFocus( event: any ) {
    const inputId = ( event.target as HTMLElement ).id as FieldId
    onFocus?.( inputId as any )
  }

  return (
    <S.Container
      onSubmit={ handleSubmit }
      onChange={ handleChange }
      onBlur={ handleBlur }
      onFocus={ handleFocus }
    >
      {inputFields.map( ( inputFieldRow, i ) => (
        <S.FormRow key={ i }>
          {inputFieldRow.map( inputField => (
            <FormInput
              key={ inputField.id }
              { ...inputField }
            />
          ) )}
        </S.FormRow>
      ) )}
      <button
        type="submit"
        style={{ 'display': 'none' }}
      />
    </S.Container>
  )
}

namespace Form {
  export interface Props extends Omit<FormHTMLAttributes<HTMLFormElement>, 'onChange' | 'onFocus' > {
    inputFields: FormInput.Props[][]
    onSubmit: ( event: FormEvent<HTMLFormElement> ) => void
    onChange( id: string, value: string, isValid: boolean, helperText: string ): void
    onFocus?: ( id: FieldId ) => void
  }
}
