import store from '@one-checkout/store'
import stepSlice from '@one-checkout/store/step.slice'
import paymentSlice from '@one-checkout/store/payment.slice'
import { navigate } from 'gatsby'

export function setMayProceedStep( shouldProceed: boolean ) {
  store.dispatch( stepSlice.actions.setMayProceedStep( shouldProceed ) )
}

export function setRedirectToken( token: string ) {
  store.dispatch( paymentSlice.actions.setRedirectToken( token ) )
}

export function verifyAndRedirectToAda(): boolean {
  const storeState = store.getState()
  const { redirectToken } = storeState.payment
  const shouldRedirect = storeState.product?.redirectToAda

  if ( shouldRedirect && redirectToken ) {
    const oneDay = 1000 * 60 * 60 * 24
    const tomorrow = new Date( Date.now() + oneDay )
    document.cookie = `TAG_TOKEN=${redirectToken};domain=taglivros.com;path=/;expires=${tomorrow}`
    const adaUrl = `${process.env[ 'GATSBY_ADA_URL' ]}`
    navigate( adaUrl )

    return true
  }

  return false
}
