const CAPTCHA_ENABLED = process.env[ 'GATSBY_RECAPTCHA_ENABLED' ] === 'true'
const RECAPTCHA_SITE_KEY = process.env[ 'GATSBY_RECAPTCHA_SITE_KEY' ]
const CAPTCHA_SCRIPT_SRC = 'https://www.google.com/recaptcha/api.js'

export default function validateCaptcha( action: string ): Promise<string> {
  if ( !CAPTCHA_ENABLED ) {
    return Promise.resolve( '' )
  }

  const captchaScript = document.head.querySelector( `script[src^="${CAPTCHA_SCRIPT_SRC}"]` )

  if ( !captchaScript ) {
    return Promise.reject( new Error( 'Captcha script not loaded' ) )
  }

  return new Promise( ( resolve, reject ) => {
    const { grecaptcha } = window as any

    grecaptcha.ready( () => {
      grecaptcha.execute( RECAPTCHA_SITE_KEY, { action } )
        .then( resolve )
        .catch( reject )
    } )
  } )
}

export { RECAPTCHA_SITE_KEY, CAPTCHA_SCRIPT_SRC }
