import { configureStore, combineReducers } from '@reduxjs/toolkit'
import promotionCouponSlice from './promotionCoupon.slice'
import identificationSlice from './identification.slice'
import leadSlice from './lead.slice'
import paymentSlice from './payment.slice'
import productSlice from './product.slice'
import stepSlice from './step.slice'
import shippingSlice from './shipping.slice'
import subscriptionSlice from './subscription.slice'

const store = configureStore( {
  'reducer': combineReducers( {
    'product': productSlice.reducer,
    'payment': paymentSlice.reducer,
    'step': stepSlice.reducer,
    'identification': identificationSlice.reducer,
    'promotionCoupon': promotionCouponSlice.reducer,
    'lead': leadSlice.reducer,
    'shipping': shippingSlice.reducer,
    'subscription': subscriptionSlice.reducer,
  } )
} )
namespace store {
  export type State = ReturnType<typeof store.getState>
}

export default store
