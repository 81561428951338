import styled from 'styled-components'

const Container = styled.div`
  a {
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    text-decoration: underline;
  }
`

export { Container }
