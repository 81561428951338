import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { FieldId } from '@one-checkout/interfaces/FormFields'
import { Icon } from 'react-feather'
import * as S from './styles'

export function FormInput( {
  label,
  id,
  required,
  Icon,
  helperText,
  isValid,
  value,
  shouldDisplayRequiredIcon,
  inputWidth,
  InfoPill,
  onFocus,
  onBlur,
  as = 'input',
  options,
  ...inputAttributes
}: FormInput.Props ) {
  const [ isFocused, setIsFocused ] = useState( false )
  const [ wasTouched, setWasTouched ] = useState( false )
  const [ hasError, setHasError ] = useState( false )

  function handleOnFocus( event: React.FocusEvent<HTMLInputElement> ) {
    setIsFocused( true )
    onFocus?.( event )
  }

  function handleOnBlur( event: React.FocusEvent<HTMLInputElement> ) {
    setIsFocused( false )

    if ( !wasTouched ) {
      setWasTouched( true )
    }

    onBlur?.( event )
  }

  useEffect( () => {
    const shouldShowError = Boolean( wasTouched && !isFocused && !isValid )
    setHasError( shouldShowError )
  }, [ wasTouched, isFocused, isValid ] )

  return (
    <S.Container
      $width={ inputWidth || null }
      $hasLabel={ Boolean( label ) }
    >
      {label &&
      <S.Label htmlFor={ id }>
        <Typography
          variant="text-medium-regular"
          color="TAG1-800"
        >
          {label} {Boolean( required && shouldDisplayRequiredIcon ) && '*'}
        </Typography>
      </S.Label>}

      <S.IconHelper>
        <S.Input
          as={ as }
          id={ id }
          name={ id }
          required={ required }
          value={ value }
          $shouldDisplayError={ Boolean( wasTouched && !isFocused && !isValid ) }
          $shouldDisplaySuccess={ Boolean( wasTouched && !isFocused && isValid ) }
          onFocus={ handleOnFocus }
          onBlur={ handleOnBlur }
          { ...inputAttributes }
        >
          {as === 'select' && options?.map( ( { value, text } ) => (
            <option
              key={ value }
              value={ value }
            >
              {text}
            </option>
          ) ) || undefined}
        </S.Input>
        {Icon && <Icon />}
      </S.IconHelper>

      {helperText && !isFocused && (
        <Typography
          variant="text-small-regular"
          color={ hasError ? 'ERROR-700' : 'TAG1-700' }
          as="small"
        >
          {helperText}
        </Typography>
      )}

      {InfoPill && <InfoPill />}
    </S.Container>
  )
}

export namespace FormInput {
  export interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    id: FieldId
    isValid: boolean
    value: string
    Icon?: Icon
    helperText?: string
    InfoPill?: ( () => JSX.Element ) | null
    shouldDisplayRequiredIcon?: boolean
    inputWidth?: {
      mobile?: string
      desktop?: string
    }
    as?: 'input' | 'select'
    options?: Option[]
  }

  export interface Option {
    value: string
    text: string
  }
}
