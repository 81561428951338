import paymentSlice from '@one-checkout/store/payment.slice'

const GA_DEFAULT_EVENT = 'ga_product_event'

const typeToEventCategory = {
  'club': 'Clubes',
  'oneTimeOffer': 'Clubes',
  'trail': 'Trilhas',
} as any

export const beginCheckoutEvent = ( productType: string ) => {
  triggerUniqueEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'begin_checkout',
    'eventCategory': typeToEventCategory[ productType ],
    'eventLabel': 'CheckoutOnepage',
  } )
  triggerEvent( {
    'event': 'initiate_checkout',
  } )
}

export const selectItemEvent = ( product: string, productType: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'select_item',
    'eventCategory': typeToEventCategory[ productType ],
    'eventLabel': product,
  } )
}

export const beginShippingInfoEvent = ( product: string ) => {
  triggerUniqueEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'begin_shipping_info',
    'eventLabel': product,
  } )
}

export const addShippingInfoRequestEvent = ( product: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_shipping_info_request',
    'eventLabel': product,
  } )
}

export const addShippingInfoEvent = ( shippingResult: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_shipping_info',
    'eventLabel': shippingResult,
  } )
}

export const addShippingInfoErrorEvent = ( error: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_shipping_info_error',
    'eventLabel': error,
  } )
}

export const generateLeadEvent = ( status: 'request' | 'error' | 'success', productType: string ) => {
  const eventAction = 'generate_lead_' + status

  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventCategory': typeToEventCategory[ productType ],
    eventAction,
  } )

  if ( status === 'success' ) {
    triggerEvent( {
      'event': 'generate_lead',
    } )
  }
}

export const giveMemberDiscountEvent = ( email: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'give_member_discount',
    'eventLabel': email,
  } )
}

export const beginPaymentInfoEvent = ( product: string, productType: string ) => {
  triggerUniqueEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'begin_payment_info',
    'eventCategory': typeToEventCategory[ productType ],
    'eventLabel': product,
  } )
}

export const selectPaymentMethodEvent = ( paymentMethod: paymentSlice.Method | undefined ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'select_payment_method',
    'eventLabel': paymentMethod,
  } )
}

export const addPaymentInfoEvent = ( product: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_payment_info',
    'eventLabel': product,
  } )
}

export const pixPaymentAttemptEvent = ( product: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'pix_payment_attempt',
    'eventLabel': product,
  } )
}

export const purchaseSubmitEvent = ( product: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'purchase_submit',
    'eventLabel': product,
  } )
}

export const purchaseRequestEvent = ( product: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'purchase_request',
    'eventLabel': product,
  } )
}

export const purchaseErrorEvent = ( error: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'purchase_error',
    'eventLabel': error,
  } )
}

export const addCouponSubmitEvent = ( coupon: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_coupon_submit',
    'eventLabel': coupon,
  } )
}

export const addCouponRequestEvent = ( coupon: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_coupon_request',
    'eventLabel': coupon,
  } )
}

export const addCouponEvent = ( coupon: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_coupon',
    'eventLabel': coupon,
  } )
}

export const addCouponErrorEvent = ( error: string ) => {
  triggerEvent( {
    'event': GA_DEFAULT_EVENT,
    'eventAction': 'add_coupon_error',
    'eventLabel': error,
  } )
}

export function productPurchaseEvent( paymentMethod: 'pix' | 'card', productPrice: number, productType: string, productName: string, promotionCoupon: string, paymentHash: string ): void {
  const fixedProductPrice = productPrice.toFixed( 2 )
  triggerEvent( {
    'event': 'product_purchase',
    paymentMethod,
    'ecommerce': {
      'value': fixedProductPrice,
      'transactionId': paymentHash,
      'discountCode': promotionCoupon,
      'items': [
        {
          'itemName': productName,
          'category': typeToEventCategory[ productType ],
          'quantity': 1,
          'price': fixedProductPrice,
        }
      ]
    }
  } )
}

function triggerEvent( data: Record<string, any> ): void {
  getGTMEventQueue().push( data )
}

function triggerUniqueEvent( data: Record<string, any> ): void {
  const alreadyHadEvent = getGTMEventQueue().filter( event => event.eventAction === data[ 'eventAction' ] )
  if ( alreadyHadEvent && alreadyHadEvent.length ) {
    return
  }
  getGTMEventQueue().push( data )
}

function getGTMEventQueue(): Array<any> {
  if ( !window.dataLayer ) {
    window.dataLayer = []
  }

  return window.dataLayer
}

