import { adaClient } from '@one-checkout/services/client'
import { backendClient, hermesClient } from './client'

export function getLead( email: string ) {
  return backendClient.get( '/v3/assinatura-email', { 'params': {
    email
  } } )
}

export function createClubLead( email: string, categoryId: number, planId: number ) {
  const body = {
    'categoria': { 'id': categoryId },
    'lead': {
      email,
    },
    'plano': { 'id': planId }
  }

  return adaClient.post( '/v1/lead', body )
}

export function updateClubLead( email: string, cpf: string, firstName: string, surName: string, phone: string, categoryId: number, planId: number ) {
  const body = {
    'categoria': { 'id': categoryId },
    'lead': {
      cpf,
      email,
      'nome': firstName,
      'sobrenome': surName,
      'telefone': phone.replace( /\D/g, '' ),
    },
    'plano': { 'id': planId }
  }

  return adaClient.patch( '/v1/lead', body )
}

export function postLeadToHermesClient( email: string, name: string, phone: string, origin: string ) {
  const hermesRequestBody = {
    'eventType': 'lead-upsert',
    email,
    name,
    'phone': phone.replace( /\D/g, '' ),
    origin,
  }

  hermesClient.post( '/site-tag/event', hermesRequestBody ).catch( error => {
    // eslint-disable-next-line no-console
    console.warn( 'Cannot post lead to hermes client', error )
  } )
}
