import { TypographyV2 as Typography } from '@taglivros/tag-components'
import React from 'react'
import { Gift as GiftIcon } from 'react-feather'
import { useTheme } from 'styled-components'
import * as S from './styles'

function DiscountLabel( { value }: DiscountLabel.Props ) {
  const theme = useTheme().v2

  return (
    <S.Container>
      <GiftIcon color={ theme.palette[ 'CTA-400' ] } />
      <Typography
        color="CTA-400"
        variant="text-medium-bold"
      >
        -{value}%
      </Typography>
    </S.Container>
  )
}
namespace DiscountLabel {
  export interface Props {
    value: number
  }
}

export default DiscountLabel
