export async function copyText( text: string ): Promise<void> {
  try {
    await copyTextWithClipboardAPI( text )
  } catch {
    copyTextWithDocumentExecCommand( text )
  }
}

function copyTextWithClipboardAPI( text: string ): Promise<void> {
  if ( !navigator.clipboard ) {
    throw new Error()
  }

  return navigator.clipboard.writeText( text )
}

function copyTextWithDocumentExecCommand( text: string ): void {
  if ( !document.execCommand ) {
    throw new Error()
  }

  const input = document.createElement( 'input' )
  input.style.position = 'fixed'
  input.style.right = window.innerWidth + 'px'
  input.style.bottom = window.innerHeight + 'px'
  document.body.appendChild( input )

  input.value = text
  input.focus()
  input.select()

  const copied = document.execCommand( 'copy' )

  input.remove()

  if ( !copied ) {
    throw new Error()
  }
}
