import Button from '@taglivros/oef_componentes/build/components/button'
import PropTypes from 'prop-types'
import React from 'react'

const StepButton = ( { disabled, hasSpaceBottom, href, invertido, handleClick, testId, title, type, noBackground, target } ) => {
  return (
    <Button
      disabled={ disabled }
      hasSpaceBottom={ hasSpaceBottom }
      href={ href }
      invertido={ invertido }
      noBackground={ noBackground }
      onClick={ handleClick }
      testId={ testId }
      type={ type }
      target={ target }
    >
      {title}
    </Button>
  )
}

StepButton.propTypes = {
  'disabled': PropTypes.bool,
  'handleClick': PropTypes.func,
  'hasSpaceBottom': PropTypes.bool,
  'href': PropTypes.string,
  'invertido': PropTypes.bool,
  'noBackground': PropTypes.bool,
  'target': PropTypes.string,
  'testId': PropTypes.string,
  'title': PropTypes.string.isRequired,
  'type': PropTypes.string,
}

StepButton.defaultProps = {
  'disabled': false,
  'handleClick': null,
  'hasSpaceBottom': true,
  'href': null,
  'invertido': false,
  'noBackground': false,
  'target': '_self',
  'testId': null,
  'type': null,
}

export { StepButton }
export default StepButton
