import { DESKTOP_BREAKPOINT, MOBILE_THRESHOLD } from '@one-checkout/constants'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: ${props => props.theme.v2.palette[ 'WHITE' ]};

  @media(max-width: ${MOBILE_THRESHOLD}px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    min-height: 132px;
    border: solid 1px ${props => props.theme.v2.palette[ 'TAG1-100' ]};
    border-radius: 4px;
    margin-top: 16px;
    padding: 16px;
  }
`

export const TextWrapper = styled.p`
  text-align: center;
  margin: auto;
`

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .final-price {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  @media (max-width: ${MOBILE_THRESHOLD}px) {
    align-items: flex-start;

    & > * {
      color: ${props => props.theme.v2.palette[ 'WHITE' ]};

      &.final-price > * {
        color: inherit;
      }

      &.old-price {
        opacity: 0.6;
      }
    }
  }

  @media (min-width: ${DESKTOP_BREAKPOINT}px) {
    & > * {
      color: ${props => props.theme.v2.palette[ 'TAG1-700' ]};

      &.final-price > * {
        color: inherit;
      }

      &:not(.final-price) {
        opacity: 0.6;
      }
    }
  }
`

export const CTA = styled.button`
  flex-grow: 1;
  min-width: fit-content;
  padding: 20px 5px;
  background-color: ${props => props.theme.v2.palette[ 'CTA-300' ]};
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  @media (min-width: ${DESKTOP_BREAKPOINT}px) {
    display: none;

    &.payment {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
`

export const ImageWrapper = styled.button`
  background-color: WHITE;
  max-width: 70%;

  @media(max-width: ${MOBILE_THRESHOLD}px) {
    // max-width: 50%;
    max-width: 100px;
  }
`
