import React from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import * as S from './styles'

export default function Header() {
  return (
    <S.Container>
      <S.Logo />
      <S.Text>
        <Typography
          variant="text-small-bold"
          color="WHITE"
        >
          Experiências Literárias
        </Typography>
      </S.Text>
    </S.Container>
  )
}
