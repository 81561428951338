import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  border: solid 1px ${props => props.theme.v2.palette[ 'TAG1-700' ]};
  border-radius: 8px;
  overflow: hidden;
`

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  &:not(:first-child) {
    border-top: solid 1px ${props => props.theme.v2.palette[ 'TAG1-700' ]};
  }

  &[data-selected=true] {
    background-color: ${props => props.theme.v2.palette[ 'TAG1-700' ]};
  }

  &[data-selected=false] {
    background-color: ${props => props.theme.v2.palette[ 'WHITE' ]};
  }

  & > * {
    &:first-child, &:last-child {
      flex-shrink: 0;
      display: flex;
    }

    &:nth-child(2) {
      flex-basis: 100%;
      text-align: left;
      margin-left: 8px;
    }
  }
`
