import React from 'react'
import { Check as CheckIcon } from 'react-feather'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { useTheme } from 'styled-components'
import { Color } from '@taglivros/tag-components/dist/themes/v2/palette'
import { ReactComponent as PixIcon } from '@one-checkout/assets/images/pix-icon.svg'
import { ReactComponent as CardIcon } from '@one-checkout/assets/images/card-icon.svg'
import * as S from './styles'
import { PAYMENT_METHODS } from '@one-checkout/constants'
import { CardMethodDetail, MethodDetail, MethodType } from '@shared/interfaces/payment/methodPayments'

function Selector( props: Selector.Props ) {
  const { selected, paymentMethodsDetail, onSelect } = props

  const pixDetails = paymentMethodsDetail.find( method => method.methodType === MethodType.PIX )
  const cardDetails = paymentMethodsDetail.find( method => method.methodType === MethodType.CREDIT_CARD )

  const { palette } = useTheme().v2

  function handleSelection( event: React.MouseEvent<HTMLButtonElement> ) {
    onSelect( event.currentTarget.name as Selector.Method )
  }

  function getFrontColor( method: Selector.Method ): Color {
    return method === selected ? 'WHITE' : 'TAG1-800'
  }

  return (
    <S.Container>
      { pixDetails && (
        <S.Button
          name="pix"
          data-selected={ selected === PAYMENT_METHODS.PIX }
          onClick={ selected !== PAYMENT_METHODS.PIX ? handleSelection : undefined }
        >
          <PixIcon stroke={ palette[ getFrontColor( PAYMENT_METHODS.PIX ) ] } />
          <Typography
            color={ getFrontColor( PAYMENT_METHODS.PIX ) }
            variant="text-medium-regular"
          >
            PIX{pixDetails.percentageDiscount > 0 && ` (-${pixDetails.percentageDiscount}%)`}
          </Typography>
          <div>
            {selected === PAYMENT_METHODS.PIX && (
              <CheckIcon
                color={ palette[ 'WHITE' ] }
                data-testid="pix-check-icon"
              />
            )}
          </div>
        </S.Button>
      )}
      { cardDetails && (
        <S.Button
          name="card"
          data-selected={ selected === PAYMENT_METHODS.CARD }
          onClick={ selected !== PAYMENT_METHODS.CARD ? handleSelection : undefined }
        >
          <CardIcon stroke={ palette[ getFrontColor( PAYMENT_METHODS.CARD ) ] } />
          <Typography
            color={ getFrontColor( PAYMENT_METHODS.CARD ) }
            variant="text-medium-regular"
          >
            Cartão de crédito{cardDetails.percentageDiscount > 0 && ` (-${cardDetails.percentageDiscount}%)`}
          </Typography>
          <div>
            {selected === PAYMENT_METHODS.CARD && (
              <CheckIcon
                color={ palette[ 'WHITE' ] }
                data-testid="card-check-icon"
              />
            )}
          </div>
        </S.Button>
      )}
    </S.Container>
  )
}
namespace Selector {
  export interface Props {
    selected?: Method
    paymentMethodsDetail: ( CardMethodDetail | MethodDetail )[]
    onSelect( method: Method ): void
  }

  export type Method = 'pix' | 'card'
}

export default Selector
