import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PromotionCoupon } from '@one-checkout/services/coupon.service'

const promotionCouponSlice = createSlice( {
  'name': 'discount',
  'initialState': {
    'couponDescription': '',
    'code': '',
    'isRecommendationCoupon': false,
    'appliedValue': {
      'price': 0,
      'percent': 0,
    },
    'state': 'unapplied',
  } as promotionCouponSlice.State,
  'reducers': {
    setPromotionCouponData( state, action: PayloadAction<promotionCouponSlice.SetPromotionCouponDataPayload> ) {
      Object.assign( state, action.payload )
    }
  }
} )
namespace promotionCouponSlice {
  export interface State {
    benefitType?: PromotionCoupon.Target
    code: string
    couponDescription: string
    isRecommendationCoupon: boolean
    appliedValue: {
      price: number
      percent: number
    }
    state: 'unapplied' | 'applied' | 'invalid'
  }

  export type SetPromotionCouponDataPayload = State
}

export default promotionCouponSlice
