import React, { useState } from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { Copy as CopyIcon } from 'react-feather'
import * as S from './styles'
import { useTheme } from 'styled-components'
import PixCopyModal from '@checkout/components/molecules/PixCopyModal'
import { copyText } from '@shared/services/clipboard'
import PixErrorModal from '@checkout/components/molecules/PixErrorModal'

function CodeNumber( { code }: CodeNumber.Props ) {
  const theme = useTheme().v2
  const [ feedback, setFeedback ] = useState<'copied' | 'error' | null>( null )

  async function handleCopy(): Promise<void> {
    try {
      await copyText( code )
      setFeedback( 'copied' )
    } catch {
      setFeedback( 'error' )
    }
  }

  return (
    <S.Container>
      <div>
        <Typography
          color="TAG1-300"
          variant="text-small-regular"
        >
          {code}
        </Typography>
        <PixCopyModal
          showPixCopyModal={ feedback === 'copied' }
          setShowPixCopyModal={ () => setFeedback( null ) }
        />
        <PixErrorModal
          showPixErrorModal={ feedback === 'error' }
          setShowPixErrorModal={ () => setFeedback( null ) }
        />
      </div>
      <button
        type="button"
        onClick={ handleCopy }
      >
        <CopyIcon
          color={ theme.palette[ 'WHITE' ] }
          size={ 16 }
        />
        <Typography
          color="WHITE"
          variant="text-medium-bold"
        >
          Copiar código PIX
        </Typography>
      </button>
    </S.Container>
  )
}
namespace CodeNumber {
  export interface Props {
    code: string
  }
}

export default CodeNumber
