export const getAbsoluteDateFromWorkDays = ( workDays: number ) => {
  const today = new Date()

  for ( let i = 1; i <= workDays; i++ ) {
    today.setDate( today.getDate() + 1 )
    if ( today.getDay() === 6 ) {
      today.setDate( today.getDate() + 2 )
    } else if ( today.getDay() === 0 ) {
      today.setDate( today.getDate() + 1 )
    }
  }
  return today
}
