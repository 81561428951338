const messages: any = {
  'TITLE': {
    'ATIVO': 'Você já possui uma assinatura',
    'INATIVO': 'Que bom te ver de novo!',
  },
  'INFORMATION': {
    'ATIVO': 'Já existe uma assinatura ativa com este %field%.',
    'INATIVO': 'Existe uma assinatura inativa com este %field%.',
  },
  'QUESTION': {
    'ATIVO': ' Você está tentando fazer uma segunda assinatura?',
    'INATIVO': ' Você está tentando reativar sua assinatura?',
  },
  'SUBTITLE': {
    'ATIVO': 'Faça uma segunda assinatura\n na Área do Associado',
    'INATIVO': 'Reative sua assinatura\n na Área do Associado',
  }
}

export { messages }
