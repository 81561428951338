import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const Image = styled.div`
  width: 90px;
  margin: 0 auto;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
