import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const shippingSlice = createSlice( {
  'name': 'shipping',
  'initialState': {
    'price': undefined,
    'monthlyPrice': undefined,
    'deadline': new Date().toString(),
  } as shippingSlice.State,
  'reducers': {
    setShippingData( state, action: PayloadAction<shippingSlice.SetShippingDataPayload> ) {
      const { price, deadline, monthlyPrice } = action.payload

      state.price = price
      state.monthlyPrice = monthlyPrice
      state.deadline = deadline
    }
  },
} )
namespace shippingSlice {
  export interface State {
    price?: number
    monthlyPrice?: number
    deadline: string
  }

  export interface SetShippingDataPayload {
    price: number
    monthlyPrice: number
    deadline: string
  }
}

export default shippingSlice
