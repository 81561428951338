import styled from 'styled-components'

const Container = styled.form`
  width: 100%;
`

const FormRow = styled.div`
  display: flex;
  gap: 8px;

  &:not(:first-child) {
    margin-top: 8px;
  }

  > * {
    z-index: 0;
  }
`

export { Container, FormRow }
