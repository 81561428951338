import React from 'react'
import * as S from './styles'

function BaseModal( { children, shouldDisplay }: BaseModal.Props ) {
  if ( !shouldDisplay ) {
    return ( null )
  }
  return (
    <S.Container>
      <S.Backdrop />
      <S.Card>
        { children }
      </S.Card>
    </S.Container>
  )
}

namespace BaseModal {
  export interface Props {
    shouldDisplay: boolean
    children: any
  }
}

export { BaseModal }
