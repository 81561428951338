import PromotionCoupon from '../PromotionCoupon'

export type Step =
  | 'identification'
  | 'identification/address'
  | 'payment'
  | 'payment/pix'
  | 'payment/pix/confirmation'
  | 'payment/card'
  | 'conclusion'

export const stepMap = {
  'identification': {
    'identification/address': {
      'payment': {
        'payment/pix': {
          'payment/pix/confirmation': {
            'conclusion': null
          }
        },
        'payment/card': {
          'conclusion': null
        }
      }
    }
  }
} as const

export const initialIdentificationData = {
  'name': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'email': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'phone': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'postalCode': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'city': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'state': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'street': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'number': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'complement': {
    'value': '',
    'isValid': true,
    'helperText': '',
    'disabled': false,
  },
  'district': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'ibge': {
    'value': '',
  },
}

export const initialPaymentData = {
  'documentNumber': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'cardNumber': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'expiry': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'cvv': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'nameOnCard': {
    'value': '',
    'isValid': false,
    'helperText': '',
    'disabled': false,
  },
  'installments': {
    'value': '6',
    'isValid': true,
    'helperText': '',
    'disabled': false,
  }
}

export const initialDiscountData: Omit<PromotionCoupon.Props, 'onChange' | 'onApply'> = {
  'couponDescription': '',
  'code': '',
  'appliedValue': {
    'price': 0,
    'percent': 0,
  },
  'state': 'unapplied',
}

export const initialPixData = {
  'code': '',
  'url': '',
  'expiryDate': new Date().toString(),
  'valid': false,
  'hash': '',
}

export const initialSubscriptionData: { status: 'ATIVO' | 'INATIVO' | '', isSubscriber: boolean, email: string } = {
  'status': '',
  'isSubscriber': false,
  'email': '',
}
