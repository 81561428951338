const monthlyPlanIds = [ 7, 16, 15, 1, 4, 11 ]
const yearlyPlanIds = [ 3, 6, 10, 14 ]

export function isMonthlyProduct( planId: number ) {
  return monthlyPlanIds.includes( planId )
}

export function isYearlyProduct( planId: number ) {
  return yearlyPlanIds.includes( planId )
}
