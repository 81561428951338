import React from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import * as S from './style'

function EmailDisplay( { shouldDisplay, email }: EmailDisplay.Props ) {
  if ( !shouldDisplay ) {
    return ( null )
  }

  return (
    <S.EmailDisplayConteiner>
      <Typography
        color="TAG1-800"
        variant="text-small-regular"
      >
        Email: { `${email}` }
      </Typography>
    </S.EmailDisplayConteiner>
  )
}

namespace EmailDisplay {
  export interface Props {
    shouldDisplay:boolean
    email: string
  }
}

export { EmailDisplay }
