import { generateLeadEvent } from '@one-checkout/services/event.service'
import { createClubLead, postLeadToHermesClient } from '@one-checkout/services/lead.service'
import store from '@one-checkout/store'
import leadSlice from '@one-checkout/store/lead.slice'

export async function registerLead( name: string, email: string, phone: string, productType: string ): Promise<void> {
  try {
    generateLeadEvent( 'request', productType )

    const product = store.getState().product!

    if ( product!.type === 'trail' ) {
      postLeadToHermesClient( email, name, phone, getTrailLeadOrigin( product.name ) )
    } else {
      const { categoryId, planId } = product as Required<typeof product>
      const { 'code': couponCode } = store.getState().promotionCoupon

      postLeadToHermesClient( email, name, phone, getClubLeadOrigin( couponCode, planId ) )
      await createClubLead( email, categoryId, planId ).catch( () => undefined )
    }

    generateLeadEvent( 'success', productType )

    const { status } = store.getState().subscription!
    const leadStatus = status === 'ATIVO' ? 'active' : 'inactive'

    setLeadStatus( leadStatus )
  } catch ( error: any ) {
    generateLeadEvent( 'error', productType )
    throw ( error )
  }
}

export function setLeadStatus( leadStatus: leadSlice.LeadStatus ) {
  store.dispatch( leadSlice.actions.setLeadStatus( leadStatus ) )
}

function getTrailLeadOrigin( productName: string ): string {
  return 'origem_checkout_' + productName.toLowerCase()
}

function getClubLeadOrigin( couponCode: string, planId: number ): string {
  const experienceCoupons = [
    'TAGEXPERIMENTE',
    'EXPERIMENTETAG'
  ]

  if ( experienceCoupons.includes( couponCode ) ) {
    return 'origem_checkout_experimente'
  }

  const planOriginsDict: Record<string, string> = {
    '1': 'origem_checkout_curadoria_mensal',
    '3': 'origem_checkout_curadoria_anual',
    '4': 'origem_checkout_ineditos_mensal',
    '6': 'origem_checkout_ineditos_anual'
  }

  return planOriginsDict[ planId ] || 'origem_checkout_nao_mapeado'
}
