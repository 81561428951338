import { TypographyV2 as Typography } from '@taglivros/tag-components'
import React from 'react'
import * as S from './styles'
import Gift from '@one-checkout/interfaces/Gift'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function GiftSummary( { gift, summaryTitle }:GiftSummary.Props ) {
  const image = gift.thumbnailImage
  const { title } = gift
  if ( !title || !image ) {
    return null
  }
  return (
    <S.Container>
      <S.TextWrapper>
        <Typography
          className="new-price"
          color="TAG1-700"
          variant="text-medium-bold"
          as="span"
        >
          { summaryTitle }:&nbsp;
        </Typography>
        <Typography
          className="new-price"
          color="TAG1-700"
          variant="text-medium-regular"
          as="span"
        >
          { title }
        </Typography>
      </S.TextWrapper>
      <S.ImageWrapper>
        <GatsbyImage
          alt={ title }
          image={ getImage( image )! }
        />
      </S.ImageWrapper>
    </S.Container>
  )
}

namespace GiftSummary {
  export interface Props {
    gift: Gift
    summaryTitle: string
  }
}

export default GiftSummary
