
import { useEffect, useState } from 'react'

export default function useMaxWidth( threshold = 1024 ): boolean {
  const [ currentWidth, setCurrentWidth ] = useState( window.innerWidth )

  useEffect( () => {
    function handleResize() {
      setCurrentWidth( window.innerWidth )
    }

    window.addEventListener( 'resize', handleResize )

    return () => window.removeEventListener( 'resize', handleResize )
  }, [] )

  return currentWidth <= threshold
}
