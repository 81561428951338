import { animationData, animationDataColor } from '@checkout/assets/lotties'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'
import React from 'react'

export function Spinner( { show, isColored, width, height } ) {
  const defaultOptions = {
    'animationData': isColored ? animationDataColor : animationData.default,
    'autoplay': true,
    'loop': true,
    'rendererSettings': {
      'preserveAspectRatio': 'xMidYMid slice'
    },
  }

  return show && (
    <Lottie
      height={ height }
      options={ defaultOptions }
      width={ width }
    />
  )
}

Spinner.propTypes = {
  'height': PropTypes.number,
  'isColored': PropTypes.bool,
  'show': PropTypes.bool.isRequired,
  'width': PropTypes.number
}

Spinner.defaultProps = {
  'height': 30,
  'isColored': false,
  'width': 30
}
