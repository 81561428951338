import styled from 'styled-components'

export const Container = styled.div`
  & > *:nth-child(2) {
    width: fit-content;
  }
`

export const CodeForm = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100% !important;

  & > * {
    flex-basis: 100%;

    &:first-child {
      flex-shrink: 1;
      padding: 0;
    }

    &:last-child {
      flex-shrink: 2;
    }
  }
`

export const CouponDiscount = styled.div`
  margin-top: 8px;
`

export const CouponDescription = styled.div`
  margin-top: 8px;
`

export const CTA = styled.button`
  background-color: ${props => props.theme.v2.palette[ 'TAG1-700' ]};
  padding: 10px 24px;
  border-radius: 8px;

  &:disabled {
    opacity: 0.3;
  }
`
