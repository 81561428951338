import styled from 'styled-components'
import { themeV2 as theme } from '@taglivros/tag-components'
import { Color } from '@taglivros/tag-components/dist/themes/v2/palette'

const Container = styled.div<Container.Props>`
  width: 100%;
  color: ${props => theme.palette[ props.$color ]};
  background: ${props => props.$backgroundColor};
  margin-top: 8px;
  padding: 8px 5px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

namespace Container {
  export interface Props {
    $backgroundColor: string
    $color: Color
  }
}

export { Container }
