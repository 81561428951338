import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const stepSlice = createSlice( {
  'name': 'step',
  'initialState': {
    'mayProceedStep': false,
  } as stepSlice.State,
  'reducers': {
    setMayProceedStep( state, action: PayloadAction<stepSlice.SetMayProceedStepPayload> ) {
      state.mayProceedStep = action.payload
    }
  }
} )
namespace stepSlice {
  export interface State {
    mayProceedStep: boolean
  }

  export type SetMayProceedStepPayload = boolean
}

export default stepSlice
