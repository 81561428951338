import styled from 'styled-components'

export const Container = styled.div`
  > *:nth-child(2) {
    margin-bottom: 16px;
  }

  .tip {
    display: block;
    margin: 16px 32px 0 32px;
    text-align: center;
  }

  .payment-installments-select {
    position: relative;
    z-index: 1;
    background-color: transparent;
  }
`
