interface PixDetails {
  DESCRIPTION_MONTHLY: [requirementDescription: string, yearlyPlan: string, howToProceed: string]
  GIFT: string
  GIFT_TEXT: string
  RECOMMENDED: string
  CHANGE_PLAN: string
  RETURN: string
  PAYMENT_CONFIRM: string
}

export const pixDetailsTexts: PixDetails = {
  'DESCRIPTION_MONTHLY': [
    'No momento, o pagamento PIX só está disponível para o',
    'Plano Anual.',
    'Clique no botão acima para aproveitar o desconto e o brinde do plano anual e também o desconto do pagamento via PIX.\nOu então troque o método de paagamento para "Cartão de Crédito".'
  ],
  'RECOMMENDED': 'Recomendado | - ',
  'GIFT': ' + Brinde',
  'GIFT_TEXT': 'BRINDE: Planner literário',
  'CHANGE_PLAN': 'Trocar para plano anual',
  'RETURN': 'Voltar',
  'PAYMENT_CONFIRM': 'Confirmar pagamento'
}

export interface PixData {
  TITLE: string
  STEPS: Array<{
    text: string
    highlight?: boolean
  }[]>
  COPY: string
  VALIDATION_CODE: string
  ATTENTION: string
  WAIT_CONFIRMATION: string
  CONFIRMED: string
  CANCELED: string
  EXPIRED: string
  RETURN: string
  QR_CODE: string
}

export const pixDataTexts: PixData = {
  'TITLE': 'Finalize o pagamento utilizando o PIX:',
  'STEPS': [
    [ { 'text': 'Enviamos uma confirmação por e-mail.' }, ],
    [
      { 'text': 'No aplicativo do seu banco, escolha a opção ' },
      { 'text': 'pagamento via PIX.', 'highlight': true },
    ],
    [
      { 'text': 'Escolha a opção ' },
      { 'text': '"Pagar com QR Code" ', 'highlight': true },
      { 'text': 'e escaneie a imagem abaixo ou escolha ' },
      { 'text': '"Pagar com Código Copia e Cola" ', 'highlight': true },
      { 'text': 'e copie o código abaixo e cole no aplicativo.' },
    ],
    [
      { 'text': 'Confirme as informações, finalize o pagamento e ' },
      { 'text': 'volte nesta página para concluir seu cadastro na TAG!', 'highlight': true },
    ],
  ],
  'COPY': 'Copiar código PIX',
  'VALIDATION_CODE': 'Código válido por 30 minutos ',
  'ATTENTION': 'Atenção: Não feche essa janela!',
  'WAIT_CONFIRMATION': 'Após realizar o pagamento, aguarde aqui a confirmação para que você possa fazer o seu cadastro de assinante!',
  'CONFIRMED': 'Pedido Confirmado!',
  'CANCELED': 'Pedido Cancelado!',
  'EXPIRED': 'O seu código PIX expirou.',
  'RETURN': 'Voltar',
  'QR_CODE': 'QR code para PIX'
}

interface PixCopyModal {
  TITLE: string
  BODY: string
  BUTTON: string
}

export const pixCopyModalTexts: PixCopyModal = {
  'TITLE': 'Código Copiado',
  'BODY': 'O código PIX foi copiado! Para concluir sua assinatura, acesse o aplicativo do seu banco e realize um pagamento PIX utilizando este código. Depois, volte nessa página para finalizar o seu cadastro no clube.',
  'BUTTON': 'Entendi',
}
