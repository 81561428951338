import {
  CardPayload,
  clubPurchase,
  experiencePurchase,
  getIsLocalSaleParamFromUrl,
  getPixPaymentStatus,
  getUtmParamFromUrl,
  oneTimeOfferPurchase,
  PixPayload,
  trailPurchase,
} from '@one-checkout/services/payment.service'
import store from '@one-checkout/store'
import paymentSlice from '@one-checkout/store/payment.slice'
import { setRedirectToken } from './page'
import PaymentMethod from '@one-checkout/components/PaymentMethod'
import { isMonthlyProduct } from '@one-checkout/services/product.service'
import { productPurchaseEvent, purchaseErrorEvent, purchaseRequestEvent } from '@one-checkout/services/event.service'
import { PAYMENT_METHODS } from '@one-checkout/constants'
import Showcase from '@one-checkout/interfaces/Showcase'
import { CardMethodDetail, MethodType } from '@shared/interfaces/payment/methodPayments'
import { setIsSubscriber } from './subscription.action'

const handlePurchaseError = ( purchaseResponse: any ) => {
  purchaseErrorEvent( purchaseResponse.errorReason )

  if ( !purchaseResponse.success ) {
    if ( purchaseResponse.code === 'NONSUBSCRIBER_CUSTOMER' ) {
      setIsSubscriber( false )
      throw purchaseResponse
    } else {
      setPaymentErrorReason( purchaseResponse.errorReason )
      throw new Error( `Payment refused with payment error code ${purchaseResponse.errorReason}` )
    }
  }
}

export async function purchase( paymentMethod: 'pix' | 'card', productPrice: number, payload: PixPayload | CardPayload ): Promise<any> {
  try {
    const { product } = store.getState()
    const servicePurchase = {
      'trail': trailPurchase,
      'club': clubPurchase,
      'oneTimeOffer': oneTimeOfferPurchase,
      'experience': experiencePurchase,
    }[ product!.type ]

    const utm = getUtmParamFromUrl()
    const isLocalSale = getIsLocalSaleParamFromUrl()

    if ( paymentMethod === PAYMENT_METHODS.CARD ) {
      purchaseRequestEvent( product!.name )
    }
    const data = await servicePurchase( product!.name as keyof Showcase, paymentMethod, { ...payload, utm, isLocalSale } )

    handlePurchaseError( data )

    const { 'billing_hash': paymentHash } = data

    setRedirectToken( data.token )

    if ( paymentMethod !== PAYMENT_METHODS.PIX ) {
      productPurchaseEvent(
        paymentMethod,
        productPrice,
        store.getState().product!.type,
        store.getState().product!.name,
        payload.coupon,
        paymentHash
      )
    }

    if ( paymentMethod === PAYMENT_METHODS.PIX ) {
      const { 'pix': { dados, url, vencimento } } = data

      const pixData = {
        'code': dados,
        url,
        'expiryDate': new Date( vencimento ),
        'valid': true,
        'hash': paymentHash,
      }

      setPixData( pixData )
    }
  } catch ( error ) {
    console.error( error )
    throw error
  }
}

export async function verifyPixPayment( paymentHash: string, productPrice: number, promotionCouponCode: string ): Promise<'paid' | 'pending'> {
  const status = await getPixPaymentStatus( paymentHash )

  if ( status === 'paid' && lastTrackedPaymentHash !== paymentHash ) {
    productPurchaseEvent(
      PAYMENT_METHODS.PIX,
      productPrice,
      store.getState().product!.type,
      store.getState().product!.name,
      promotionCouponCode,
      paymentHash
    )
    lastTrackedPaymentHash = paymentHash
  }

  return status
}

export function setPixData( pixData: any ) {
  store.dispatch( paymentSlice.actions.setPixData( pixData ) )
}

export function setExpiredPix() {
  store.dispatch( paymentSlice.actions.expiryPix() )
}

export function setPaymentMethod( paymentMethod: PaymentMethod.Props['selected'] ) {
  store.dispatch( paymentSlice.actions.setPaymentMethod( paymentMethod ) )
}

export function setPaymentError( hasError: boolean ) {
  store.dispatch( paymentSlice.actions.setPaymentError( hasError ) )
}

export function setPaymentErrorReason( errorReason: string ) {
  store.dispatch( paymentSlice.actions.setPaymentErrorReason( errorReason ) )
}

export function setPaymentData( paymentData: any ) {
  store.dispatch( paymentSlice.actions.setPaymentFields( paymentData ) )
}

export function setInstallmentsValue( installments: string ) {
  const storeState = store.getState()
  setPaymentData( {
    ...storeState.payment,
    'installments': {
      ...storeState.payment.installments,
      'value': installments,
    }
  } )
}

export function setIsPaying( isPaying: boolean ) {
  store.dispatch( paymentSlice.actions.setIsPaying( isPaying ) )
}

export function resetDocumentNumber() {
  store.dispatch( paymentSlice.actions.resetDocumentNumber() )
}

export function getInstallments() {
  const storeState = store.getState()
  if ( storeState.payment.method === PAYMENT_METHODS.CARD && !isMonthlyProduct( Number( storeState.product!.planId ) ) ) {
    if ( !storeState.payment.installments.value ) {
      const cardMethodDetails = storeState.product!.paymentMethodsDetail.find( method => method.methodType === MethodType.CREDIT_CARD ) as CardMethodDetail
      setInstallmentsValue( cardMethodDetails.defaultInstallment.toString() )
    }
    return Number( storeState.payment.installments.value )
  }
  return 1
}

let lastTrackedPaymentHash: string
