const redirectsByProductName = <Record<string, string>> {
}

export function getRedirectURLForProductName( productName?: string ): string | undefined {
  if ( process.env[ 'GATSBY_ACTIVE_ENV' ] === 'dev' || process.env[ 'GATSBY_ACTIVE_ENV' ] === 'hlg' ) {
    return
  }

  const HOME_URL = undefined
  return productName && redirectsByProductName[ productName ] || HOME_URL
}
