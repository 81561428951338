import React from 'react'
import * as S from './styles'
import Modal from '@taglivros/oef_componentes/build/components/modal'
import StepButton from '@checkout/components/atoms/StepButton'
import { Typography } from '@taglivros/tag-components'
import { pixCopyModalTexts as constants } from '../../../assets/constants/payment/paymentPixTexts'

const PixCopyModal = ( { showPixCopyModal, setShowPixCopyModal }: PixCopyModal.Props ): React.ReactElement => {
  return (
    <Modal
      altura="auto"
      largura="450px"
      show={ showPixCopyModal }
    >
      <S.Container>
        <Typography
          as="h2"
          color="GREY-700-TXT"
          fontFamily="notoSans"
          fontSizeDesktop="s"
          fontSizeMobile="s"
          fontWeight="l"
          variant="customText"
        >
          { constants.TITLE }
        </Typography>

        <Typography
          as="p"
          color="GREY-700-TXT"
          fontFamily="notoSans"
          fontWeight="m"
          variant="text3"
        >
          { constants.BODY }
        </Typography>

        <StepButton
          handleClick={ () => setShowPixCopyModal( false ) }
          title={ constants.BUTTON }
        />
      </S.Container>
    </Modal>
  )
}

namespace PixCopyModal {
  export interface Props {
    showPixCopyModal: boolean
    setShowPixCopyModal: ( showPixCopyModal: boolean ) => void
  }
}

export default PixCopyModal
