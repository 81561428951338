import axios from 'axios'

const backendURL = process.env[ 'GATSBY_BACKEND_API_URL' ]!
const hermesURL = process.env[ 'GATSBY_HERMES_API_URL' ]!
const adaURL = process.env[ 'GATSBY_ADA_API_URL' ]!

const headers = {
  'Content-Type': 'application/json'
}

export const backendClient = axios.create( {
  'baseURL': backendURL,
  headers,
} )

export const hermesClient = axios.create( {
  'baseURL': hermesURL,
  headers,
} )

export const adaClient = axios.create( {
  'baseURL': adaURL,
  headers,
} )
