import { MOBILE_THRESHOLD } from '@one-checkout/constants'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: ${props => props.theme.v2.palette[ 'WHITE' ]};
  flex-wrap: wrap;

  @media(max-width: ${MOBILE_THRESHOLD}px) {
    position: relative;
    flex-direction: row;
    min-height: 132px;
    padding: 16px;
    border: solid 2px ${props => props.theme.v2.palette[ 'TAG1-100' ]};
    border-radius: 4px;
  }
`

export const ImageWrapper = styled.div`
  flex-shrink: 0;

  img {
    max-width: 200px;
    max-height: 150px;
    object-fit: contain !important;
  }

  @media(max-width: ${MOBILE_THRESHOLD}px) {
    max-width: 100px;
    max-height: 100px;
    img {
      object-fit: contain !important;
      max-width: 100px;
      max-height: 100px;
    }
  }
`

export const DescriptionWrapper = styled.div`
  flex-basis: min-content;
  flex-grow: 1;
  max-width: fit-content;
`

export const Text = styled.div`
  text-align: center;

  &:last-child {
    margin-top: 4px;
  }
`
