import { MOBILE_THRESHOLD } from '@one-checkout/constants'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.v2.palette[ 'TAG1-800' ]};
  overflow: auto;

  @media(max-width: ${MOBILE_THRESHOLD}px) {
    background-color: white;
  }

  > header {
    flex-shrink: 0;
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 20px;
  }

  *::-webkit-scrollbar-thumb {
    background: ${props => props.theme.v2.palette[ 'TAG1-300' ]};
    border: solid 3px white;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const Desktop = styled.div`
  display: grid;
  grid-template-columns: 400px 300px;
  gap: 32px;
  justify-content: center;
  height: calc(100% - 144px);

  > * {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 0;
    gap: 16px;
    height: fit-content;
    overflow: auto;
    padding-bottom: 20px;

    > * {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 25px;
      flex-shrink: 0;
      padding: 16px;
      border-radius: 16px;
      background-color: white;
      overflow: auto;

      > *:not(.identification-summary) {
        padding: 0 16px;
      }
    }
  }
`
export const Mobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 100%;
  width: 100%;

  > * {
    &:first-child {
      padding: 0 16px 16px 16px;
    }

    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0 16px 16px 16px;
      flex-basis: 100%;

      > *:not(.identification-summary) {
        padding: 0 16px;
      }
    }

    ${() => css`
      &:last-child:not(${Conclusion}) {
        position: sticky;
        bottom: 0;
      }
    `}
  }
`

export const ProcedureCTA = styled.button`
  padding: 20px 88px !important;
  margin: 0 16px 16px 16px;
  background-color: ${props => props.theme.v2.palette[ 'CTA-300' ]};
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
`

export const Conclusion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  & > *:last-child {
    @media (max-width: ${MOBILE_THRESHOLD}px) {
      border: solid 2px ${props => props.theme.v2.palette[ 'TAG1-100' ]};
      border-radius: 4px;
      padding: 30px !important;
    }
  }
`
