import { formatCardNumber, formatCep, formatCPFDocument, formatCvv, formatEmail, formatExpirationDate, formatName, formatNameOnCard, formatPhone } from '@checkout/utils/formatter'
import { FieldId } from '@one-checkout/interfaces/FormFields'

function mask( inputId: FieldId, inputValue: string ) {
  const masks: Record<MaskName, MaskHandler> = {
    'name': formatName,
    'email': formatEmail,
    'phone': formatPhone,
    'postalCode': formatCep,
    'documentNumber': formatCPFDocument,
    'cardNumber': formatCardNumber,
    'expiry': formatExpirationDate,
    'cvv': formatCvv,
    'nameOnCard': formatNameOnCard,
  }

  if ( inputId in masks ) {
    return masks[ inputId as MaskName ]( inputValue )
  } else {
    return inputValue
  }
}

type MaskName = 'name' | 'email' | 'phone' | 'postalCode' | 'documentNumber' | 'cardNumber' | 'expiry' | 'cvv' | 'nameOnCard'
type MaskHandler = ( name: string ) => string

export { mask }
