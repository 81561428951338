import React from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'
import ShippingLabel from '../IdentificationSummary/ShippingLabel'
import * as S from './styles'

function Conclusion( { productImage }: Conclusion.Props ) {
  return (
    <S.Container>
      <S.Image>
        <GatsbyImage
          image={ getImage( productImage )! }
          alt="Compra concluida"
        />
      </S.Image>
      <S.Description>
        <Typography
          color="CTA-400"
          variant="subtitle-large-bold"
        >
          Compra concluída!
        </Typography>
        <Typography
          color="TAG1-700"
          variant="text-medium-regular"
        >
          Sua <strong>Coleção Nada Anônimas</strong> está garantida! Agora é só aguardar a entrega!
          Os envios acontecem a partir de 08/12/23 e,
          assim que a sua compra for despachada, você receberá as informações de rastreamento por e-mail. Até lá!
        </Typography>
      </S.Description>
      <ShippingLabel />
    </S.Container>
  )
}
namespace Conclusion {
  export interface Props {
    productImage: ImageDataLike
  }
}

export default Conclusion
