import React from 'react'
import * as S from './styles'
import { Icon, XCircle as CloseIcon } from 'react-feather'
import { Color } from '@taglivros/tag-components/dist/themes/v2/palette'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { useTheme } from 'styled-components'

export function FormInfoPill( { Icon, text, backgroundColor, color, onClose }: FormInfoPill.Props ) {
  const theme = useTheme().v2

  return (
    <S.Container
      $backgroundColor={ backgroundColor }
      $color={ color }
    >
      <Icon width={ 24 } />
      <Typography
        color={ color }
        variant="text-medium-bold"
      >
        {text}
      </Typography>
      {onClose && (
        <CloseIcon
          color={ theme.palette[ color ] }
          onClick={ onClose }
          data-testid="close-icon"
        />
      )}
    </S.Container>
  )
}

namespace FormInfoPill {
  export interface Props {
    Icon: Icon
    text: string
    backgroundColor: string
    color: Color
    onClose?(): void
  }
}
