import React, { useState } from 'react'
import { setIsSubscriber } from '@one-checkout/actions/subscription.action'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { navigate } from 'gatsby'
import { resetEmail } from '@one-checkout/actions/identification.action'
import { BaseModal } from '../BaseModal'
import { EmailDisplay } from './EmailDisplay'
import { messages } from './constants'
import { resetDocumentNumber } from '@one-checkout/actions/payment.action'
import { ReactComponent as Loader } from '../../assets/images/loader.svg'

const WHATSAPP_LINK = 'https://api.whatsapp.com/send/?phone=555191968623&text=Oi,+TAG!&app_absent=0'

function SubscriptionErrorModal( { shouldDisplay, resetSteps, subscriptionStatus, documentNumber, shouldDisplayEmail, subscriptionEmail }: SubscriptionErrorModal.Props ) {
  const [ showLoader, setShowLoader ] = useState( false )
  const status = subscriptionStatus ? subscriptionStatus : ''

  function getInformation(): string {
    const informationMessage = messages[ 'INFORMATION' ][ status ]
    if ( !informationMessage ) {
      return ''
    }
    let field = 'e-mail'
    if ( documentNumber ) {
      field = 'CPF'
    }
    return informationMessage.replace( '%field%', field )
  }

  function handleButtonAccount() {
    setShowLoader( true )
    navigate( `${process.env[ 'GATSBY_ADA_URL' ]}` )
  }

  function handleButtonRetypeEmail() {
    resetSteps()
    resetEmail()
    resetDocumentNumber()
    setShowLoader( false )
    setIsSubscriber( false )
  }

  return (
    <BaseModal shouldDisplay={ shouldDisplay }>
      <S.LeftContent>
        <S.Title>
          <Typography
            color="TAG1-700"
            variant="subtitle-large-bold"
          >
            { messages[ 'TITLE' ][ status ] }
          </Typography>
        </S.Title>
        <Typography
          color="TAG1-900"
          variant="text-medium-regular"
        >
          { getInformation() }
        </Typography>
        <Typography
          color="TAG1-900"
          variant="text-medium-bold"
        >
          { messages[ 'QUESTION' ][ status ] }
        </Typography>
      </S.LeftContent>
      <S.CenterContent>
        <S.Subtitle>
          <Typography
            color="TAG1-700"
            variant="subtitle-large-bold"
          >
            { messages[ 'SUBTITLE' ][ status ] }
          </Typography>
        </S.Subtitle>
        <EmailDisplay
          shouldDisplay={ shouldDisplayEmail }
          email={ subscriptionEmail }
        />
      </S.CenterContent>

      <S.ButtonAccount
        name="account"
        className="button-account"
        onClick={ handleButtonAccount }
        disabled={ showLoader }
      >
        {!showLoader ? (
          <Typography
            color="WHITE"
            variant="button-medium-bold"
          >
            ACESSAR CONTA
          </Typography>
        ) : (
          <Loader />
        )}
      </S.ButtonAccount>

      <S.ButtonRetypeEmail
        name="retype-email"
        className="modal-retype-email"
        onClick={ handleButtonRetypeEmail }
      >
        <Typography
          color="TAG1-800"
          variant="subtitle-medium-regular"
        >
          INSERIR OUTRO EMAIL
        </Typography>

      </S.ButtonRetypeEmail>

      <S.Footer>
        <S.Contact>
          <Typography
            color="TAG1-800"
            variant="text-small-regular"
          >
              Não consegue acessar sua conta?<br />
              Fale conosco por Whatsapp:
            <a href={ WHATSAPP_LINK }>
              <S.Link>
                <Typography
                  color="TAG1-800"
                  variant="text-small-regular"
                >
                  51 99196 8623
                </Typography>
              </S.Link>
            </a>
          </Typography>
        </S.Contact>
      </S.Footer>
    </BaseModal>
  )
}

namespace SubscriptionErrorModal {
  export interface Props {
    shouldDisplay: boolean
    resetSteps: Function
    subscriptionStatus: string | null
    documentNumber: string
    shouldDisplayEmail: boolean
    subscriptionEmail: string
  }
}

export { SubscriptionErrorModal }
