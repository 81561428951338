import React, { useMemo } from 'react'
import { formatPrice, onlyNumbers } from '@checkout/utils/formatter'
import { Form } from '@one-checkout/components/Form'
import { FormInput } from '@one-checkout/components/FormInput'
import Selector from '../Selector'
import Payment from 'payment'
import { CardFlag } from '@checkout/components/atoms/CardFlag'
import { ChevronDown as AngleDownIcon } from 'react-feather'
import { useTheme } from 'styled-components'
import { CardMethodDetail, MethodDetail, MethodType } from '@shared/interfaces/payment/methodPayments'
import { PAYMENT_METHODS } from '@one-checkout/constants'

function FormData( props: FormData.Props ) {
  const {
    paymentMethod,
    documentNumber,
    cardNumber,
    expiry,
    cvv,
    nameOnCard,
    installments,
    productPrice,
    shippingPrice,
    monthlyPlan,
    paymentMethodsDetail,
    onChange,
    onSubmit,
  } = props

  const theme = useTheme().v2

  const cardBrand = useMemo( () => Payment.fns.cardType( onlyNumbers( cardNumber.value ) ), [ cardNumber ] )

  let fields: FormInput.Props[][] = [
    [
      {
        ...documentNumber,
        'id': 'documentNumber',
        'label': 'CPF',
        'autoFocus': true,
        'autoComplete': 'disabled',
        'maxLength': 14,
      },
    ],
  ]

  if ( paymentMethod === PAYMENT_METHODS.CARD ) {
    fields = fields.concat( [
      [
        {
          ...cardNumber,
          'id': 'cardNumber',
          'label': 'Número do cartão',
          'Icon': () => (
            <CardFlag flag={ cardBrand } />
          )
        },
      ],
      [
        {
          ...expiry,
          'id': 'expiry',
          'label': 'Val. (MM/AA)',
          'inputWidth': { 'mobile': '50%', 'desktop': '50%' },
        },
        {
          ...cvv,
          'id': 'cvv',
          'label': 'CVV',
          'inputWidth': { 'mobile': '50%', 'desktop': '50%' },
        },
      ],
      [
        {
          ...nameOnCard,
          'id': 'nameOnCard',
          'label': 'Nome do titular do cartão',
          'maxLength': 100,
        },
      ],
    ] )
  }

  if ( paymentMethod === PAYMENT_METHODS.CARD && !monthlyPlan ) {
    const cardMethodDetails = paymentMethodsDetail.find( method => method.methodType === MethodType.CREDIT_CARD ) as CardMethodDetail
    const shipping = shippingPrice || 0
    fields = fields.concat( [
      [
        {
          ...installments,
          'className': 'payment-installments-select',
          'id': 'installments',
          'label': 'Opções de Parcelamento',
          'as': 'select',
          'options': cardMethodDetails.installmentsOptions.map( installments => ( {
            'value': String( installments ),
            'text': `${installments}x de ${formatPrice( ( productPrice + shipping ) / installments, true )}`
          } ) ),
          'Icon': () => (
            <AngleDownIcon color={ theme.palette[ 'TAG1-800' ] } />
          )
        }
      ]
    ] )
  }

  return (
    <Form
      inputFields={ fields }
      onSubmit={ onSubmit }
      onChange={ onChange }
    />
  )
}
namespace FormData {
  export interface Props extends Fields {
    paymentMethod: Selector.Method
    productPrice: number
    shippingPrice?: number
    monthlyPlan?: boolean
    paymentMethodsDetail: ( CardMethodDetail | MethodDetail )[]
    onChange( id: string, value: string, isValid: boolean, helperText: string ): void
    onSubmit(): void
  }

  export interface Fields {
    documentNumber: FieldData
    cardNumber: FieldData
    expiry: FieldData
    cvv: FieldData
    nameOnCard: FieldData
    installments: FieldData
  }

  export type FieldData = Omit<FormInput.Props, 'id' | 'label' | 'Icon'>
}

export default FormData
