import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Image = styled.img`
  width: 248px;
`

export const Store = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  & > a > img {
    width: 120px;
  }
`
