import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const leadSlice = createSlice( {
  'name': 'leadStatus',
  'initialState': {
    'status': 'inactive' as leadSlice.LeadStatus
  } as leadSlice.State,
  'reducers': {
    'setLeadStatus'( state, action: PayloadAction<leadSlice.SetLeadStatusPayload> ) {
      state.status = action.payload
    }
  }
} )
namespace leadSlice {
  export interface State {
    status: LeadStatus
  }

  export type LeadStatus = 'active' | 'inactive'

  export type SetLeadStatusPayload = LeadStatus
}

export default leadSlice
