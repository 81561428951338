import React from 'react'
import { Modal } from '@shared/components/atoms/Modal'
import { setPaymentError, setPaymentErrorReason } from '@one-checkout/actions/payment.action'

type ErrorReasonData = { title: string, content: string }

const buildMessageByReasonData = ( title: string, content: string ): ErrorReasonData => ( {
  title,
  content
} )

const errorMessagesByReason: Record<string, ErrorReasonData> = {
  'NOT_ENOUGH_BALANCE': buildMessageByReasonData( 'Cartão sem limite disponível', 'O cartão inserido não possui limite disponível para esta compra. Ajuste o limite, insira outro cartão ou mude o método de pagamento e tente novamente.' ),
  'INVALID_CARD_NUMBER': buildMessageByReasonData( 'Cartão com dados incorretos', 'Os dados do cartão não são válidos. Confirme os dados, insira outro cartão ou mude o método de pagamento e tente novamente.' ),
  'REFUSED_CARD': buildMessageByReasonData( 'Cartão Recusado', 'O cartão inserido foi recusado. Confirme os dados, consulte seu limite, entre em contato com seu banco, insira outro cartão ou mude o método de pagamento e tente novamente.' ),
  'UNKNOWN_ERROR': buildMessageByReasonData( 'Falha no pagamento', 'Houve um erro durante a finalização da sua compra. Confirme seus dados e tente novamente. Se o erro persistir, entre em contato pelo e-mail contato@taglivros.com.br' )
}

function PaymentErrorModal( { shouldDisplay, errorReason }: PaymentErrorModal.Props ) {
  function handleButtonClick() {
    setPaymentError( false )
    setPaymentErrorReason( '' )
  }

  const { title, content }: ErrorReasonData = errorMessagesByReason[ errorReason ] || errorMessagesByReason[ 'UNKNOWN_ERROR' ]!

  return (
    <Modal
      title={ title }
      content={ content }
      buttonText="Entendi"
      shouldDisplay={ shouldDisplay }
      onButtonClick={ handleButtonClick }
    />
  )
}
namespace PaymentErrorModal {
  export interface Props {
    shouldDisplay: boolean
    errorReason: string
  }
}

export { PaymentErrorModal }
