
import { DESKTOP_BREAKPOINT } from '@one-checkout/constants'
import { themeV2 as theme } from '@taglivros/tag-components'
import styled from 'styled-components'

const Container = styled.div<Container.Props>`
  width:  ${props => props.$width?.mobile || '100%'};
  display: flex;
  flex-direction: column;

  @media(min-width: ${DESKTOP_BREAKPOINT}px) {
    width:  ${props => props.$width?.desktop || '100%'};
    margin-top: ${props => props.$hasLabel && '8px'};
  }
`
namespace Container {
  export interface Props {
    $width: {
      mobile?: string
      desktop?: string
    } | null
    $hasLabel: boolean
  }
}

const Input = styled.input<Input.Props>`
  ${props => {
    if ( props.$shouldDisplayError ) {
      return `border: 1px solid ${theme.palette[ 'ERROR-200' ]};`
    } else if ( props.$shouldDisplaySuccess ) {
      return `border: 1px solid ${theme.palette[ 'SUCCESS-400' ]};`
    } else {
      return `border: 1px solid ${theme.palette[ 'TAG1-700' ]};`
    }
  }}

  border-radius: 4px;
  padding: 8px;
  width: 100%;
  appearance: none;

  font-family: 'Lato';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${theme.palette[ 'TAG1-800' ]};
  background-color: ${theme.palette[ 'WHITE' ]};

  @media(min-width: ${DESKTOP_BREAKPOINT}px) {
    font-size: 16px;
    line-height: 22px;
  }

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    font-family: 'Lato';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${theme.palette[ 'TAG1-300' ]};

    @media(min-width: ${DESKTOP_BREAKPOINT}px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &:disabled {
    border-color: ${theme.palette[ 'TAG1-300' ]};
    color: ${theme.palette[ 'TAG1-300' ]};

    & + svg {
      color: ${theme.palette[ 'TAG1-300' ]};
    }
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 1px solid ${theme.palette[ 'TAG1-700' ]};
  }
`
namespace Input {
  export interface Props {
    $shouldDisplayError: boolean
    $shouldDisplaySuccess: boolean
  }
}

const IconHelper = styled.div`
  position: relative;

  & > svg.is-clickable,  & > [aria-label=animation].is-clickable {
    cursor: pointer;
  }

  & > svg:last-child, & > [aria-label=animation]:last-child {
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const Label = styled.label`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export { Container, Input, IconHelper, Label }
