import React, { useEffect, useState } from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import * as S from './styles'
import CodeNumber from './CodeNumber'
import { Spinner } from '@checkout/components/atoms/Spinner'
import { setExpiredPix } from '@one-checkout/actions/payment.action'

function PixConfirmation( props: PixConfirmation.Props ) {
  const {
    code,
    url,
    expiryDate,
    onVerifyPayment,
  } = props
  const parsedExpiryDate = new Date( expiryDate )

  const [ now, setNow ] = useState( () => Date.now() )

  const remaingMinutes = getRemaingMinutes( now, parsedExpiryDate.getTime() )
  const deadlineText = getDeadlineText( parsedExpiryDate )

  useEffect( () => {
    const timerId = window.setInterval( () => {
      setNow( Date.now() )
      onVerifyPayment()
    }, 5000 )

    return () => window.clearInterval( timerId )
  }, [] )

  useEffect( () => {
    if ( !remaingMinutes ) {
      setExpiredPix()
    }
  }, [ remaingMinutes ] )


  if ( remaingMinutes <= 0 ) {
    return (
      <S.Container className="expired">
        <Typography
          color="ERROR-700"
          variant="subtitle-large-bold"
        >
          Pedido expirado
        </Typography>
        <Typography
          color="TAG1-800"
          variant="text-medium-regular"
        >
          O Seu código PIX expirou.
        </Typography>
      </S.Container>
    )
  }

  return (
    <S.Container>
      <Typography
        color="CTA-400"
        variant="subtitle-large-bold"
      >
        Pedido confirmado
      </Typography>
      <S.Guide>
        <Typography
          color="TAG1-800"
          variant="text-medium-regular"
        >
          Enviamos uma confirmação por e-mail.
        </Typography>
        <Typography
          color="TAG1-800"
          variant="text-medium-bold"
        >
          Finalize o pagamento utilizando o PIX:
        </Typography>
        <Typography
          color="TAG1-800"
          variant="text-medium-regular"
        >
          <strong>1.</strong> No aplicativo do seu banco, escolha a opção pagamento via PIX.
        </Typography>
        <Typography
          color="TAG1-800"
          variant="text-medium-regular"
        >
          <strong>2.</strong> Escolha a opção “Pagar com QR Code” e escaneie a imagem abaixo ou escolha “Pagar com Código Copia e Cola” e copie o código abaixo e cole no aplicativo.
        </Typography>
        <Typography
          color="TAG1-800"
          variant="text-medium-regular"
        >
          <strong>3.</strong> Confirme as informações e finalize o pagamento.
        </Typography>
      </S.Guide>
      <S.QRSection>
        <S.Image
          src={ url }
          alt="QRCode Pix"
        />
        <CodeNumber code={ code } />
        <Typography
          color="TAG1-800"
          variant="text-medium-bold"
        >
          Código válido por {remaingMinutes} minuto{remaingMinutes !== 1 ? 's' : ''} (até {deadlineText})
        </Typography>
        <Spinner
          show={ true }
          isColored={ true }
          width={ 50 }
          height={ 50 }
        />
      </S.QRSection>
      <Typography
        color="TAG1-800"
        variant="text-medium-regular"
      >
        Após a compra, atualizaremos esta página com uma confirmação de compra
      </Typography>
    </S.Container>
  )
}
namespace PixConfirmation {
  export interface Props {
    code: string
    url: string
    expiryDate: string
    onVerifyPayment(): void
  }
}

export default PixConfirmation

function getRemaingMinutes( from: number, to: number ): number {
  const diff = to - from
  return Math.round( diff / 1000 / 60 )
}

function getDeadlineText( date: Date ): string {
  const hours = String( date.getHours() ).padStart( 2, '0' )
  const minutes = String( date.getMinutes() ).padStart( 2, '0' )

  return `${hours}:${minutes}`
}
