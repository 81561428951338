import React, { useState } from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { Form } from '../Form'
import { Spinner } from '@checkout/components/atoms/Spinner'
import { FormInput } from '../FormInput'
import { FormInfoPill } from '../FormInfoPill'
import { Gift } from 'react-feather'
import * as S from './styles'
import { formatPrice } from '@checkout/utils/formatter'
import { useTheme } from 'styled-components'
import { PromotionCoupon as PromotionCouponService } from '@one-checkout/services/coupon.service'

function PromotionCoupon( props: PromotionCoupon.Props ) {
  const {
    code,
    benefitType,
    couponDescription,
    appliedValue,
    state,
    onChange,
    onApply,
  } = props

  const theme = useTheme().v2

  const [ isApplying, setIsApplying ] = useState( false )

  const formField: FormInput.Props = {
    'id': 'couponCode' as any,
    'value': code,
    'isValid': state !== 'invalid',
    'helperText': state === 'invalid' && 'Código inexistente' || '',
    'disabled': false,
    'Icon': () => (
      <Spinner
        show={ isApplying }
        isColored={ true }
        width={ 25 }
        height={ 25 }
      />
    ),
  }

  const showPrice = appliedValue.price > 0
  const showPercent = appliedValue.percent > 0
  const showDiscount = showPrice || showPercent
  const showTotalFreightDiscount = benefitType && [ 'FRETE_TODOS', 'FRETE_1_KIT' ].includes( benefitType ) && !showPrice

  function handleChange( _id: string, value: string ): void {
    onChange( value.toUpperCase() )
  }

  async function handleApply(): Promise<void> {
    if ( !code ) {
      return
    }

    setIsApplying( true )
    await onApply()
    setIsApplying( false )
  }

  return <S.Container>
    <Typography
      color="TAG1-800"
      variant="text-medium-regular"
    >
      Cupom
    </Typography>
    {state === 'applied' ? (
      <>
        <FormInfoPill
          Icon={ Gift }
          text={ code }
          color="CTA-400"
          backgroundColor={ `${theme.palette[ 'CTA-400' ]}19` }
          onClose={ () => onChange( '' ) }
        />
        {showTotalFreightDiscount ? (
          <S.CouponDiscount>
            <Typography
              color="CTA-400"
              variant="text-medium-regular"
            >
              Este cupom é para desconto de FRETE. Preencha os dados de endereço para ver o desconto no valor da sua assinatura
            </Typography>
          </S.CouponDiscount>
        ) : null }
        {showDiscount && !showTotalFreightDiscount ? (
          <S.CouponDiscount>
            <Typography
              color="CTA-400"
              variant="text-medium-regular"
            >
              {showPrice && `-${formatPrice( appliedValue.price, true )}`}
              {showPercent && ` (${appliedValue.percent}%)`}
            </Typography>
          </S.CouponDiscount>
        ) : null }
        {couponDescription ? (
          <S.CouponDescription>
            <Typography
              color="TAG1-800"
              variant="text-medium-regular"
            >
              {couponDescription}
            </Typography>
          </S.CouponDescription>
        ) : null }
      </>
    ) : (
      <S.CodeForm>
        <Form
          inputFields={ [ [ formField ] ] }
          onChange={ handleChange }
          onSubmit={ handleApply }
        />
        <S.CTA
          type="submit"
          onClick={ handleApply }
          disabled={ isApplying || !code }
        >
          <Typography
            color="WHITE"
            variant="button-medium-bold"
          >
            Aplicar
          </Typography>
        </S.CTA>
      </S.CodeForm>
    )}
  </S.Container>
}
namespace PromotionCoupon {
  export interface Props {
    code: string
    benefitType?: PromotionCouponService.Target,
    couponDescription: string,
    appliedValue: {
      price: number
      percent: number
    }
    state: 'unapplied' | 'applied' | 'invalid'
    onChange( code: string ): void
    onApply(): Promise<void>
  }
}

export default PromotionCoupon
