/* eslint-disable max-statements */
/* eslint-disable prefer-destructuring */
import { useMemo, useState } from 'react'

export default function useStep<$Step extends string>( mapObject: useStep.Map ) {
  const map = useMemo( () => createMap<$Step>( mapObject ), [] )
  const [ state, setState ] = useState<any>( () => ( { 'current': map.firstChild!, 'concluded': null } ) )

  const { current, concluded } = state

  function proceed( step?: $Step ): void {
    let nextStep: MapDocument.Element<$Step> | undefined | null

    if ( !step ) {
      if ( concluded === current.parentElement ) {
        nextStep = current.firstChild

        if ( !nextStep ) {
          throw new Error( `There are no more steps beyond "${current.getAttribute( 'name' )}"` )
        }

        setState( {
          'current': nextStep,
          'concluded': current
        } )
      } else {
        setState( {
          'current': concluded!.firstChild,
          concluded,
        } )
      }
    } else {
      nextStep = current.querySelector( `[name="${step}"]` )

      if ( nextStep?.getAttribute( 'name' ) === step ) {
        setState( {
          'current': nextStep,
          'concluded': nextStep.parentElement as any,
        } )
      } else {
        nextStep = current.parentElement as any

        while ( nextStep ) {
          if ( nextStep.getAttribute( 'name' ) === step ) {
            break
          }

          const child = nextStep.querySelector( `[name="${step}"]` ) as any

          if ( child ) {
            nextStep = child
            break
          }

          nextStep = nextStep.parentElement as any
        }

        if ( !isConcluded( nextStep!.getAttribute( 'name' ) ) ) {
          setState( {
            'current': nextStep,
            'concluded': nextStep!.parentElement as any,
          } )
        } else {
          setState( {
            'current': nextStep!,
            concluded,
          } )
        }
      }
    }
  }

  function isConcluded( step: $Step ): boolean {
    for ( let stepElement = concluded; stepElement; stepElement = stepElement.parentElement as any ) {
      if ( stepElement.getAttribute( 'name' ) === step ) {
        return true
      }
    }

    return false
  }

  function resetSteps(): void {
    setState( { 'current': map.firstChild!, 'concluded': null } )
  }

  return {
    'current': current.getAttribute( 'name' ),
    'concluded': concluded?.getAttribute( 'name' ),
    proceed,
    isConcluded,
    resetSteps,
  }
}
namespace useStep {
  export type Map = {
    [K: string]: Map | null
  }
}

interface MapDocument<$Step extends string> extends XMLDocument {
  firstChild: MapDocument.Element<$Step> | null
}
namespace MapDocument {
  export interface Element<$Step extends string> extends globalThis.Element {
    firstChild: Element<$Step> | null
    getAttribute( qualifiedName: 'name' ): $Step
    querySelector( name: `[name="${$Step}"]` ): Element<$Step> | null
  }
}

function createMap<$Step extends string>( mapObject: object ): MapDocument<$Step> {
  const xmlDocument = document.implementation.createDocument( null, '' )
  createStepNodes( xmlDocument, mapObject )
  return xmlDocument as MapDocument<$Step>

  function createStepNodes( xmlNode: XMLDocument | HTMLElement, mapObject: Record<string, any> ): void {
    for ( const stepName in mapObject ) {
      const stepNode = xmlDocument.createElement( 'step' )
      stepNode.setAttribute( 'name', stepName )

      xmlNode.appendChild( stepNode )

      const mapChildren = mapObject[ stepName ]

      if ( mapChildren ) {
        createStepNodes( stepNode, mapChildren )
      }
    }
  }
}
