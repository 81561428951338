import { FormFields } from '@one-checkout/interfaces/FormFields'

function checkFormValidity( formState: FormFields, section: Section ) {
  const formFields: Record<Section, Array<keyof FormFields>> = {
    'identification': [ 'name', 'email', 'phone', 'postalCode', 'city', 'state', 'street', 'number', 'complement', 'district' ],
    'pix': [ 'documentNumber' ],
    'card': [ 'documentNumber', 'cardNumber', 'expiry', 'cvv', 'nameOnCard' ],
  }

  return isFormValid( formState, formFields[ section ] )
}

function isFormValid( formState: FormFields, fields: Array<keyof FormFields> ) {
  for ( const field of fields ) {
    if ( !formState[ field as keyof FormFields ]!.isValid ) {
      return false
    }
  }

  return true
}

type Section = 'identification' | 'pix' | 'card'

export { checkFormValidity }

