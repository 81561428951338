import { AxiosInstance } from 'axios'
import { setup as withCache } from 'axios-cache-adapter'

export default function create( baseURL: string ): AxiosInstance {
  const client = withCache( {
    baseURL,
    'cache': {
      'exclude': {
        'query': false,
      },
    },
  } )

  client.interceptors.response.use(
    ( response ) => response,
    ( err ) => {
      return Promise.reject( err.response?.data || err.response )
    }
  )

  return client
}
