import React from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import * as S from './styles'
import cabeceiraImage from '@one-checkout/assets/images/cabeceira.png'
import googlePlayImage from '@one-checkout/assets/images/google-play.png'
import appStoreImage from '@one-checkout/assets/images/app-store.png'

function Advertising() {
  return (
    <S.Container>
      <S.Image
        src={ cabeceiraImage }
        alt="Cabeceira"
      />
      <Typography
        color="TAG1-700"
        variant="text-medium-regular"
      >
        Quer uma ajuda para organizar seus livros e manter o hábito de leitura?
        Conheça o cabeceira, aplicativo da TAG.
      </Typography>
      <S.Store>
        <a href="https://play.google.com/store/apps/details?id=br.com.taglivros.cabeceira">
          <S.Image
            src={ googlePlayImage }
            alt="Google Play"
          />
        </a>
        <a href="https://apps.apple.com/us/app/cabeceira-leia-mais-livros/id1481633011">
          <S.Image
            src={ appStoreImage }
            alt="App Store"
          />
        </a>
      </S.Store>
    </S.Container>
  )
}

export default Advertising
