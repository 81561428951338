import createClient from '@shared/http/client'

const adaClient = createClient( process.env.GATSBY_ADA_API_URL )
const backendClient = createClient( process.env.GATSBY_BACKEND_API_URL )
const hermesClient = createClient( process.env.GATSBY_HERMES_API_URL )

export {
  adaClient,
  backendClient,
  hermesClient,
}
