import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Product from '@shared/interfaces/Product'

const productSlice = createSlice( {
  'name': 'product',
  'initialState': null as productSlice.State,
  'reducers': {
    'setProduct': ( state: Product | null, action: PayloadAction<productSlice.SetProductPayload> ) => {
      return Object.assign( state || {}, action.payload )
    }
  }
} )
namespace productSlice {
  export type State = Product | null
  export type SetProductPayload = Product
}

export default productSlice
