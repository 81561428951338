import { validationTexts } from '@checkout/assets/constants/validationTexts'
import {
  validateCEP,
  validateEmail,
  validateName,
  validateNumber,
  validateStreet,
  validateCPF,
  validateCardFlag,
  validateCardExpirationMonth,
  validateExpiredCardDate,
} from '@checkout/utils/inputValidation'
import { FieldId } from '@one-checkout/interfaces/FormFields'
import Payment from 'payment'

export function validate( inputId: FieldId, inputValue: string ) {
  const validators: Record<ValidatorName, ValidatorHandler> = {
    'name': nameValidator,
    'email': emailValidator,
    'phone': phoneValidator,
    'postalCode': postalCodeValidator,
    'street': streetValidator,
    'number': numberValidator,
    'complement': complementValidator,
    'district': districtValidator,
    'documentNumber': documentNumberValidator,
    'cardNumber': cardNumberValidator,
    'expiry': expiryValidator,
    'cvv': cvvValidator,
    'nameOnCard': nameOnCardValidator,
    'installments': () => 'success',
  }

  let validationMessage = ''

  if ( inputId in validators ) {
    validationMessage = validators[ inputId as ValidatorName ]( inputValue )
  }

  if ( validationMessage === 'success' ) {
    return { 'isValid': true, 'helperText': '' }
  } else {
    return { 'isValid': false, 'helperText': validationMessage }
  }
}

const isEmpty = ( input: string ) => input === ''
const overflowsMaxLength = ( input: string, maxLength: number ) => input.length > maxLength
const reachesMinLength = ( input: string, minLength: number ) => input.length >= minLength

function nameValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( overflowsMaxLength( input, 100 ) ) {
    return validationTexts.MAX_LENGTH_MESSAGE
  } else if ( !validateName( input ) ) {
    return validationTexts.INVALID_NAME_MESSAGE
  }

  return 'success'
}

function emailValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( !reachesMinLength( input, 7 ) ) {
    return validationTexts.EMAIL_MIN_LENGTH_MESSAGE
  } else if ( overflowsMaxLength( input, 250 ) ) {
    return validationTexts.MAX_LENGTH_MESSAGE
  } else if ( !validateEmail( input ) ) {
    return validationTexts.INVALID_EMAIL_MESSAGE
  }

  return 'success'
}

function phoneValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( !reachesMinLength( input, 14 ) ) {
    return validationTexts.INVALID_PHONE_MESSAGE
  }

  return 'success'
}

function postalCodeValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( !validateCEP( input ) ) {
    return validationTexts.INVALID_CEP_MESSAGE
  }

  return 'success'
}

function streetValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( overflowsMaxLength( input, 60 ) ) {
    return validationTexts.MAX_LENGTH_MESSAGE
  } else if ( !validateStreet( input ) ) {
    return validationTexts.INVALID_STREET_MESSAGE
  }

  return 'success'
}

function numberValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( overflowsMaxLength( input, 5 ) ) {
    return validationTexts.MAX_LENGTH_MESSAGE
  } else if ( !validateNumber( input ) ) {
    return validationTexts.INVALID_NUMBER_MESSAGE
  }

  return 'success'
}

function complementValidator( input:string ) {
  if ( !isEmpty( input ) ) {
    if ( overflowsMaxLength( input, 50 ) ) {
      return validationTexts.MAX_LENGTH_MESSAGE
    } else if ( !validateStreet( input ) ) {
      return validationTexts.INVALID_COMPLEMENT_MESSAGE
    }
  }

  return 'success'
}

function districtValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( overflowsMaxLength( input, 50 ) ) {
    return validationTexts.MAX_LENGTH_MESSAGE
  } else if ( !validateStreet( input ) ) {
    return validationTexts.INVALID_DISTRICT_MESSAGE
  }

  return 'success'
}

function documentNumberValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( !validateCPF( input ) ) {
    return validationTexts.INVALID_CPF_MESSAGE
  }

  return 'success'
}

function cardNumberValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( !Payment.fns.validateCardNumber( input ) ) {
    return validationTexts.INVALID_CARD_MESSAGE
  } else if ( !validateCardFlag( input ) ) {
    return validationTexts.INVALID_FLAG_MESSAGE
  }

  return 'success'
}

function expiryValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( !reachesMinLength( input, 5 ) ) {
    return validationTexts.INVALID_CARD_EXPIRATION_MESSAGE
  } else if ( !validateCardExpirationMonth( input ) ) {
    return validationTexts.INVALID_MONTH_MESSAGE
  } else if ( !validateExpiredCardDate( input ) ) {
    return validationTexts.EXPIRED_CARD_MESSAGE
  }

  return 'success'
}

function cvvValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( !reachesMinLength( input, 3 ) ) {
    return validationTexts.INVALID_CVV_MESSAGE
  }

  return 'success'
}

function nameOnCardValidator( input: string ) {
  if ( isEmpty( input ) ) {
    return validationTexts.REQUIRED_FIELD_MESSAGE
  } else if ( overflowsMaxLength( input, 64 ) ) {
    return validationTexts.MAX_LENGTH_MESSAGE
  } else if ( !validateName( input ) ) {
    return validationTexts.INVALID_NAME_MESSAGE
  }

  return 'success'
}

type ValidatorName = 'name' | 'email' | 'phone' | 'postalCode' | 'street' | 'number' | 'complement' | 'district' | 'documentNumber' | 'cardNumber' | 'expiry' | 'cvv' | 'nameOnCard' | 'installments'
type ValidatorHandler = ( name: string ) => string
