import styled from 'styled-components'
import { MOBILE_THRESHOLD } from '@one-checkout/constants'
import { ReactComponent as LogoImage } from '@site/images/header/logo_colored.svg'

export const Container = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 144px;
  background-color: ${props => props.theme.v2.palette[ 'TAG1-800' ]};

  @media(max-width: ${MOBILE_THRESHOLD}px) {
    position: relative;
    height: 82px;
    padding-top: 3px;

    & > * {
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: calc(100% + 210px);
      border-radius: 550px / 10px;
      background-color: ${props => props.theme.v2.palette[ 'TAG1-800' ]};
    }
  }
`

export const Logo = styled( LogoImage )`
  transform: scale(1.6);

  @media(max-width: ${MOBILE_THRESHOLD}px) {
    transform: scale(1);
  }
`

export const Text = styled.div`
  margin-top: -18px;
  margin-left: 23px;
  width: min-content;

  @media (max-width: ${MOBILE_THRESHOLD}px) {
    margin-top: -12px;
    margin-left: 5px;
    line-height: 1em;
  }
`

