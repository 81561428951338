import React from 'react'
import { pixCopyModalTexts as constants } from '../../../assets/constants/payment/paymentPixTexts'
import { Modal } from '@shared/components/atoms/Modal'

const PixErrorModal = ( { showPixErrorModal, setShowPixErrorModal }: PixCopyModal.Props ): React.ReactElement => {
  return (
    <Modal
      title="Erro ao copiar o código"
      content="Desculpe, não foi possível copiar o código PIX. Por favor, copie o código manualmente."
      buttonText={ constants.BUTTON }
      shouldDisplay={ showPixErrorModal }
      onButtonClick={ () => setShowPixErrorModal( false ) }
    />
  )
}

namespace PixCopyModal {
  export interface Props {
    showPixErrorModal: boolean
    setShowPixErrorModal: ( showPixCopyModal: boolean ) => void
  }
}

export default PixErrorModal
