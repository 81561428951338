import React from 'react'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { Markdown } from 'react-showdown'
import { ImageDataLike, GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as S from './styles'
import useMaxWidth from '@one-checkout/hooks/useMaxWidth'
import { MOBILE_THRESHOLD } from '@one-checkout/constants'

function ProductDescription( props: ProductDescription.Props ) {
  const { image, title, description } = props

  const isMobile = useMaxWidth( MOBILE_THRESHOLD )

  const [ titleVariant, descriptionVariant ] = isMobile
    ? [ 'subtitle-medium-light', 'text-small-regular' ] as const
    : [ 'subtitle-large-light', 'text-medium-regular' ] as const

  return (
    <S.Container>
      <S.ImageWrapper>
        <GatsbyImage
          alt={ title }
          image={ getImage( image )! }
        />
      </S.ImageWrapper>
      <S.DescriptionWrapper>
        <S.Text>
          <Typography
            color="TAG1-700"
            variant={ titleVariant }
          >
            <Markdown markdown={ title } />
          </Typography>
        </S.Text>
        <S.Text>
          <Typography
            color="TAG1-700"
            variant={ descriptionVariant }
          >
            <Markdown markdown={ description } />
          </Typography>
        </S.Text>
      </S.DescriptionWrapper>
    </S.Container>
  )
}
namespace ProductDescription {
  export interface Props {
    image: ImageDataLike
    title: string
    description: string
  }
}

export default ProductDescription
