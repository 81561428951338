/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Selector from './Selector'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import * as S from './styles'
import FormData from './FormData'
import { PAYMENT_METHODS } from '@one-checkout/constants'
import { CardMethodDetail, MethodDetail } from '@shared/interfaces/payment/methodPayments'

function PaymentMethod( props: PaymentMethod.Props ) {
  const { selected, fields, productPrice, shippingPrice, monthlyPlan, paymentMethodsDetail, purchaseActionText, onChangeMethod, onChangeData, onSubmit } = props

  const paymentMethodSelector = () => {
    if ( monthlyPlan ) {
      return null
    }
    return (
      <>
        <Typography
          color="TAG1-800"
          variant="text-medium-regular"
        >
          Selecione um método de pagamento
        </Typography>
        <Selector
          selected={ selected }
          paymentMethodsDetail={ paymentMethodsDetail }
          onSelect={ onChangeMethod }
        />
      </>
    )
  }

  return (
    <S.Container>
      { paymentMethodSelector() }
      {selected && (
        <>
          <FormData
            { ...fields }
            paymentMethod={ selected }
            monthlyPlan={ monthlyPlan }
            productPrice={ productPrice }
            shippingPrice={ shippingPrice }
            paymentMethodsDetail={ paymentMethodsDetail }
            onChange={ onChangeData }
            onSubmit={ onSubmit }
          />
          {selected === PAYMENT_METHODS.PIX && (
            <Typography
              className="tip"
              color="TAG1-700"
              variant="text-medium-bold"
            >
            Clique em "{purchaseActionText}" para gerar o código de pagamento via PIX
            </Typography>
          )}
        </>
      )}
    </S.Container>
  )
}
namespace PaymentMethod {
  export interface Props {
    selected?: Method
    fields: FormData.Fields
    purchaseActionText: string
    productPrice: number
    shippingPrice?: number
    paymentMethodsDetail: ( CardMethodDetail | MethodDetail )[]
    monthlyPlan?: boolean
    onChangeMethod( method: Method ): void
    onChangeData: FormData.Props['onChange']
    onSubmit(): void
  }

  export type Method = Selector.Method
}

export default PaymentMethod
